import proj4 from 'proj4';
import { degToRad } from 'three/src/math/MathUtils';
import Ellipse from '../@types/Ellipse';
import Number3 from '../@types/Number3';
import Tree from '../@types/Tree';

const calculateGirthOld = (girth: Ellipse) => 2 * Math.PI * Math.sqrt((Math.pow(girth.rX, 2) + Math.pow(girth.rY, 2)) / 2);

const calculateGirth = (girth: Ellipse) => {
  const { rX, rY } = girth;

  const formula = Math.PI * (3 * (rX + rY) - Math.sqrt((3 * rX + rY) * (3 * rY + rX)));

  return formula;
};

const generateUrl = (id: string) => window.location.pathname.split('/').slice(0, 4).join('/') + '/' + id;

const generateLocationUrl = (id: string) => `${window.location.pathname.split('/').slice(0, 3).join('/')}/location/${id}`;

const parseGirth = (tree: Tree) => {
  if (tree?.girth_1_0m) {
    return {
      dX: tree.girth_1_0m_offset_x,
      dY: tree.girth_1_0m_offset_y,
      rX: tree.girth_1_0m_ellipse_a || 1,
      rY: tree.girth_1_0m_ellipse_b || 1,
      rotation: tree.girth_1_0m_ellipse_direction,
      diameter: null,
    };
  } else {
    const GIRTH_FALLBACK = 2;

    const radius = GIRTH_FALLBACK / (2 * Math.PI);
    return {
      dX: 0,
      dY: 0,
      rX: radius,
      rY: radius,
      rotation: 0,
      diameter: null,
    };
  }
};

const parseCanopy = (tree: Tree) => {
  const crownExcentricity = tree.crown_excentricity
      ? Array.isArray(tree.crown_excentricity)
        ? tree.crown_excentricity
        : JSON.parse(tree.crown_excentricity).coordinates
      : [0, 0];
  if (tree?.canopy_ellipse_a) {
    return {
      dX: crownExcentricity[0],
      dY: crownExcentricity[1],
      rX: tree.canopy_ellipse_a || 1,
      rY: tree.canopy_ellipse_b || 1,
      rotation: degToRad(tree.canopy_ellipse_direction),
      diameter: null,
    };
  } else {
    const GIRTH_FALLBACK = 2;

    const radius = GIRTH_FALLBACK / (2 * Math.PI);
    return {
      dX: 0,
      dY: 0,
      rX: radius,
      rY: radius,
      rotation: 0,
      diameter: null,
    };
  }
};

const projectLocal = (pos: number[]) => proj4('EPSG:4326', 'localProjection', pos || []) as Number3;

export { calculateGirth, generateLocationUrl, generateUrl, parseCanopy, parseGirth, projectLocal };

