import { useHistory } from 'react-router';
import { Icon } from '..';
import { useAuth } from '../../providers/auth-provider';
import { useUser } from '../../providers/user-provider';

const UserDropdown = ({ name }) => {
  const { logout } = useAuth();
  const { hasRole } = useUser();
  const history = useHistory();

  const _handleLogout = () => {
    logout();
  }

  const _handlePreferences = path => () => history.push(`/preferences/${path}`);

  return (
    <div className="user-dropdown-wrapper">
      <div className="user-wrapper">
        <img src={`https://eu.ui-avatars.com/api/?name=${name}`} alt={name} />
        <div>
          <span className="name">{ name }</span><br/>
        </div>
      </div>
      <div className="separator" />
      <Action label='Personal preferences' icon='user-cog' action={_handlePreferences('personal')} />
      { hasRole('admin') && <Action label='Administration' icon='users-cog' action={_handlePreferences('administration')} /> }
      { hasRole('admin') && <Action label='Administrational parameters' icon='tags' action={_handlePreferences('administrational-paramenters')} /> }
      <div className="separator" />
      <Action label='Log out' icon='sign-out-alt' primary action={_handleLogout} />
    </div>
  )
};

const Action = ({ label, icon, primary, action }) => {
  return (
    <div className="dropdown-action-wrapper" onClick={action}>
      <div className="icon-wrapper">
        <Icon icon={icon} />
      </div>
      <span className={`${primary ? 'primary' : ''} label`}>{ label }</span>
    </div>
  )
}

export default UserDropdown;