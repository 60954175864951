import { PureComponent } from "react";
import * as THREE from "three";
import { OrbitControls } from 'three-stdlib';
import { cloneDeep } from "lodash";
import { RECLASS_TOOLS } from "../../routes/Validation/SegmentationValidation";
import { areVectorsEqual, isLastLineIntersectingPreviousLines, isPointInPolygon } from "../../utils/geometryUtils";
import { convertValueBetweenRanges } from "../../utils/mathUtils";
import {
  createCircle,
  createTargetPointObject,
  projectPointsToPlane,
  transformPointsToTwoDimensionalPlane,
} from "../../utils/threeJsHelper";
import ValidationTool from "../../@types/enums/ValidationTool";
import { getPointClassLabel, getRgbColorByClassification } from "../../providers/config";

const GeometryError = {
  InterSectionError: "intersectionError",
};

const viewDefaultZ = 150;
const polygonToolMinSnapThreshold = 0.025;
const polygonToolMaxSnapThreshold = 0.2;

class SegmentationMover extends PureComponent {
  static frustumSize = 12;
  static minZoom = 0.2;
  static maxZoom = 7;
  static reclassifyLineHexcolor = 0xf2c200;

  constructor(props) {
    super(props);

    this.raycaster = new THREE.Raycaster();

    this.repaintRequestPending = false;

    this.state = {
      moving: false,
      reclassification: {
        loading: false,
        mousePos: {
          x: 0,
          y: 0,
        },
      },
      actionMode: this.props.actionMode,
      reclassPoints: [],
    };

    this.centerHeight = 0;
  }

  componentDidMount = () => {
    const resizeObserver = new ResizeObserver((entries) => {
      const borderBoxSize = entries[0]?.borderBoxSize[0];
      if (!borderBoxSize) return;

      this.resizeViewport(borderBoxSize.inlineSize, borderBoxSize.blockSize);
    });
    resizeObserver.observe(this.container);

    window.addEventListener("keydown", this.handleKeyPress);

    this.camera = new THREE.OrthographicCamera(
      (0.5 * SegmentationMover.frustumSize * 5) / 2,
      (0.5 * SegmentationMover.frustumSize * 5) / 2,
      SegmentationMover.frustumSize / 2,
      SegmentationMover.frustumSize / -2,
      0.1,
      10000
    );
    this.camera.up.set(0, 0, 1);
    this.camera.updateProjectionMatrix();

    this.resetFilter(true);

    if (!this.props.isUp) {
      this.props.setRotationHandler(() => this.handleRotation);
    }

    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      canvas: this.canvas,
    });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.localClippingEnabled = true;

    this.controls = new OrbitControls(this.camera, this.canvas);
    this.controls.enablePan = true;
    this.controls.rotateSpeed = 1.0;
    this.controls.minZoom = SegmentationMover.minZoom;
    this.controls.maxZoom = SegmentationMover.maxZoom;
    this.props.viewControls.current.push(this.controls);

    this.controls.addEventListener("change", () => {
      if (this.props.editorName === this.props.activeEditor) {
        const azimuthAngle = this.controls.getAzimuthalAngle();
        for (const otherControls of this.props.viewControls.current) {
          if (otherControls !== this.controls) {
            otherControls.setAzimuthalAngle(azimuthAngle);
          }
        }
      }

      const scale = 1 / this.camera.zoom;
      this.sphere.scale.setScalar(scale);

      this.repaint();
    });

    this.controls.mouseButtons = {
      LEFT: null,
      MIDDLE: THREE.MOUSE.DOLLY,
      RIGHT: THREE.MOUSE.ROTATE,
    };

    this.sphere = new THREE.Mesh(
      new THREE.SphereGeometry(0.08),
      new THREE.MeshBasicMaterial({ color: 'red' })
    );

    this.scene = new THREE.Scene();
    this.scene.name = this.props.editorName;
    this.scene.up.set(0, 0, 1);
    this.scene.background = new THREE.Color(this.props.background || 0x000000);
    this.scene.add(this.sphere);

    this.updateFromProps({});
    this.controls.update();
    this.repaint();
  };

  componentWillUnmount = () => {
    window.removeEventListener("keydown", this.handleKeyPress);
    this.props.viewControls.current = this.props.viewControls.current.filter(c => c !== this.controls);
    this.controls.dispose();
    this.sphere.geometry.dispose();
    this.sphere.material.dispose();
    this.scene.remove(this.sphere);
    if (this.points) {
      this.points.geometry.dispose();
      this.points.material.dispose();
      this.scene.remove(this.points);
    }
    if (this.environmentPoints) {
      this.environmentPoints.geometry.dispose();
      this.environmentPoints.material.dispose();
      this.scene.remove(this.environmentPoints);
    }
    this.renderer.dispose();
  };

  componentDidUpdate = (prevProps) => {
    this.updateFromProps(prevProps);
  };

  resizeViewport = (width, height) => {
    if (!this.container) return;

    this.aspectRatio = width / height;
    this.camera.left = -0.5 * SegmentationMover.frustumSize * this.aspectRatio;
    this.camera.right = 0.5 * SegmentationMover.frustumSize * this.aspectRatio;
    this.camera.top = 0.5 * SegmentationMover.frustumSize;
    this.camera.bottom = -0.5 * SegmentationMover.frustumSize;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
    this.renderer.render(this.scene, this.camera);
  };

  updateFromProps = (prevProps) => {
    if (
      this.environmentPoints &&
      (
        prevProps.environmentPointcloud !== this.props.environmentPointcloud ||
        prevProps.isEnvironmentVisible !== this.props.isEnvironmentVisible ||
        this.environmentPoints.visible !== prevProps.isEnvironmentVisible
      )
    ) {
      this.environmentPoints.visible = this.props.isEnvironmentVisible;
    }

    if (prevProps.environmentPointcloud !== this.props.environmentPointcloud) {
      if (this.environmentPoints) {
        this.environmentPoints.geometry.dispose();
        this.environmentPoints.material.dispose();
        this.scene.remove(this.environmentPoints);
      }

      if (this.props.environmentPointcloud) {
        this.environmentPoints = new THREE.Points(
          this.props.environmentPointcloud.geometry,
          this.props.environmentPointcloud.createMaterial(
            THREE,
            this.props.pointSize ?? 0.5
          )
        );
        this.environmentPoints.up.set(0, 0, 1);
        this.scene.add(this.environmentPoints);
      }
    }

    if (prevProps.background !== this.props.background) {
      this.scene.background = new THREE.Color(
        this.props.background || 0x000000
      );
    }

    if (prevProps.pointcloud !== this.props.pointcloud) {
      if (this.points) {
        this.points.geometry.dispose();
        this.points.material.dispose();
        this.scene.remove(this.points);
      }

      let boundingSphere;
      // pointcloud can be false
      if (this.props.pointcloud) {
        this.pointcloud = this.props.pointcloud;
        const points = new THREE.Points(
          this.props.pointcloud.geometry,
          this.props.pointcloud.createMaterial(
            THREE,
            this.props.pointSize ?? 0.5
          )
        );
        this.points = points;
        this.points.up.set(0, 0, 1);

        this.points.geometry.computeBoundingBox();

        this.scene.add(this.points);

        boundingSphere = points.geometry.boundingSphere;
      } else {
        boundingSphere = new THREE.Sphere(
          new THREE.Vector3(...this.props.position),
          8
        );
      }

      // Set orbit target and camera position on first pointcloud load
      if (this.controls && !prevProps.pointcloud) {
        this.controls.target.set(
          boundingSphere.center.x,
          boundingSphere.center.y,
          boundingSphere.center.z
        );
        if (this.props.isUp) {
          this.camera.position.z = viewDefaultZ;
        } else {
          this.camera.position.set(
            boundingSphere.center.x + boundingSphere.radius * 5,
            boundingSphere.center.y + boundingSphere.radius * 5,
            boundingSphere.center.z
          );
          this.centerHeight = boundingSphere.center.z;
        }
      }
    }

    // PointCloud updates
    if (
      (prevProps.pointcloud !== this.props.pointcloud ||
        prevProps.environmentPointcloud !== this.props.environmentPointcloud) &&
      this.props.environmentPointcloud &&
      this.pointcloud
    ) {
      const treeMins = new THREE.Vector3(...this.pointcloud.pc.mins);
      const envMins = new THREE.Vector3(
        ...this.props.environmentPointcloud.pc.mins
      );
      const diffBetweenTreeAndEnvironment = envMins.clone().sub(treeMins);

      this.environmentPoints.position.copy(diffBetweenTreeAndEnvironment);
    }

    this.reactToPointSizeChanges(prevProps.pointSize);

    if (prevProps.colors !== this.props.colors) {
      this.reactToColorChanges(this.pointcloud);
    }

    const newState = { ...this.state };

    // ActionMode updates
    if (prevProps.actionMode !== this.props.actionMode) {
      newState.actionMode = this.props.actionMode;
    }

    // ReclassPoint updates
    if (this.state.reclassPoints !== this.props.reclassPoints) {
      try {
        this.reactToReclassPointChanges(this.props.reclassPoints);
        newState.reclassPoints = this.props.reclassPoints;
      } catch (err) {
        if (err.message === GeometryError.InterSectionError) {
          this.props.showToast("Point cannot intersect the polygon!", "error");
        }
        this.props.setReclassPoints(this.state.reclassPoints);
      }
    }

    // Do reclassify
    if (this.state.reclassification.loading) {
      let newGeom;

      if (this.props.reclassTool === RECLASS_TOOLS.BRUSH) {
        newGeom = this.reclassify(this.state.reclassification.mousePos);
      } else if (this.props.reclassTool === RECLASS_TOOLS.POLYGON) {
        if (!this.polygonEndTarget) {
          this.props.showToast("Polygon must end in starting point!", "error");
          newState.reclassification.loading = false;
        } else {
          newGeom = this.reclassifyPolygon();
          this.props.setReclassPoints([]);
          this.handlePolygonToolFinishedCleanUp();
        }
      }

      if (newGeom) {
        this.props.updateGeometry(newGeom);
        newState.reclassification = {
          mousePos: { x: 0, y: 0 },
          loading: false,
        };
      }
    }

    if (prevProps.pointcloud !== this.props.pointcloud) {
      this.reactToColorChanges(this.props.pointcloud);
    }

    if (prevProps.lockView !== this.props.lockView) {
      if (this.props.lockView) {
        this.controls.minAzimuthAngle = this.controls.getAzimuthalAngle();
        this.controls.maxAzimuthAngle = this.controls.getAzimuthalAngle();
        this.controls.minPolarAngle = this.controls.getPolarAngle();
        this.controls.maxPolarAngle = this.controls.getPolarAngle();
      }
      else {
        if (this.props.isUp) {
          this.controls.minPolarAngle = -Math.PI;
          this.controls.maxPolarAngle = -Math.PI;
        }
        else {
          this.controls.minPolarAngle = -Infinity;
          this.controls.maxPolarAngle = Infinity;
        }
        this.controls.minAzimuthAngle = -Infinity;
        this.controls.maxAzimuthAngle = Infinity;
      }
      this.controls.update();
    }

    this.sphere.position.copy(this.props.position);

    this.repaint();
    this.setState(newState);
  }

  reactToPointSizeChanges = (previousPointSize) => {
    if (!this.pointcloud || !this.props.pointcloud || previousPointSize === this.props.pointSize) {
      return;
    }
    this.points.material = this.pointcloud.createMaterial(THREE, this.props.pointSize ?? 0.5);
  }

  reactToColorChanges = (pc) => {
    const pointCount = pc?.pc.pointCount;
    if (pointCount) {
      const classColors = {};

      const colors = new Float32Array(pointCount * 3);
      const classification = pc?.geometry.getAttribute("classification");

      for (const [index, pointClass] of classification.array.entries()) {
        let rgb = [255, 255, 255];
        if (Object.keys(classColors).includes(pointClass.toString())) {
          rgb = classColors[pointClass];
        } else {
          const pointClassLabel = getPointClassLabel(pointClass);
          const hexOverride = this.props.colors[pointClassLabel];
          rgb = getRgbColorByClassification(pointClass, hexOverride) ?? [
            255, 255, 255,
          ];
          classColors[pointClass] = rgb;
        }

        colors[3 * index] = rgb[0] / 255;
        colors[3 * index + 1] = rgb[1] / 255;
        colors[3 * index + 2] = rgb[2] / 255;
      }

      const newColors = new THREE.Float32BufferAttribute(colors, 3);
      this.pointcloud.geometry.setAttribute("color", newColors);
      this.points.geometry.setAttribute("color", newColors);
    }
  }

  reactToPolygonChanges = ({ points, color, plane, setPolygon, setLine }) => {
    this.scene.remove(this.scene.getObjectByName("IntersectionMarker"));
    this.scene.remove(this.scene.getObjectByName("StartPoint"));

    if (!points.length || this.props.isUp) {
      this.scene.remove(this.scene.getObjectByName("LineObject"));
      return;
    }

    if (points.length === 1) {
      this.scene.remove(this.scene.getObjectByName("LineObject"));

      const circle = new THREE.CircleGeometry(2 / this.camera.zoom / 30, 20);
      const point = new THREE.Mesh(
        circle,
        new THREE.MeshBasicMaterial({ color })
      );
      point.rotation.copy(this.camera.rotation);
      point.position.add(points[0].point);
      point.name = "StartPoint";

      this.renderObjectOnTop(point);
      this.scene.add(point);
    }

    plane = plane ?? this.computeCameraPlane(points[0].point);
    const { poly, line, error } = this.projectPointsToPolygon(points, plane);
    if (error) throw new Error(error);

    const polygonLineObject = new THREE.Line(
      line,
      new THREE.LineBasicMaterial({ color, linewidth: 2 })
    );
    polygonLineObject.name = "LineObject";

    const LineObject = this.scene.getObjectByName("LineObject");
    if (LineObject) this.scene.remove(LineObject);

    this.renderObjectOnTop(polygonLineObject);
    this.scene.add(polygonLineObject);

    setLine(polygonLineObject);
    setPolygon(poly);
  };

  reactToReclassPointChanges = (points) => {
    const setReclassPoly = (polygon) => (this.reclassPoly = polygon);
    const setReclassLineObject = (line) => (this.reclassLineObject = line);
    this.reactToPolygonChanges({
      points,
      color: SegmentationMover.reclassifyLineHexcolor,
      plane: this.reclassPlane,
      setPolygon: setReclassPoly,
      setLine: setReclassLineObject,
    });
  };

  projectPointsToPolygon = (points, plane) => {
    const projectedPoints = projectPointsToPlane(points, plane);

    const line = new THREE.BufferGeometry().setFromPoints(projectedPoints);
    if (points.length < 3) return { line };

    const polygon2DPoints = transformPointsToTwoDimensionalPlane({
      points: projectedPoints,
      plane,
      scene: this.scene,
    });

    if (!this.polygonEndTarget) {
      const intersectionOnPolygonEdges =
        isLastLineIntersectingPreviousLines(polygon2DPoints);
      if (intersectionOnPolygonEdges) {
        console.error(
          "New line is intersecting the polygon",
          intersectionOnPolygonEdges
        );
        return { error: GeometryError.InterSectionError };
      }
    }

    return {
      poly: new THREE.BufferGeometry().setFromPoints(projectedPoints),
      line,
    };
  };

  computeCameraPlane = (point) => {
    const target = new THREE.Vector3();
    this.camera.getWorldDirection(target);
    return new THREE.Plane().setFromNormalAndCoplanarPoint(
      target.normalize(),
      point
    );
  };

  renderObjectOnTop = (obj) => {
    obj.renderOrder = 999;
    obj.material.depthTest = false;
    obj.material.depthWrite = false;
    obj.onBeforeRender = function (renderer) {
      renderer.clearDepth();
    };
  };

  setZoom = (zoom = 0.5) => {
    if (zoom && this.camera.zoom !== zoom) {
      this.camera.zoom = zoom;
      this.camera.updateProjectionMatrix();
      this.repaint();
    }
  };

  resetFilter = () => {
    if (this.points) {
      this.points.material.userData.filterPoints(null);
      this.points.material.needsUpdate = true;
    }
  };

  repaint = () => {
    if (!this.renderer || this.repaintRequestPending) return;
    
    this.repaintRequestPending = true;
    requestAnimationFrame(() => {
      this.renderer.render(this.scene, this.camera);
      this.repaintRequestPending = false;
    });
  };

  handleKeyPress = (e) => {
    if (this.state.actionMode === ValidationTool.Reclassify) {
      switch (e.key) {
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
          const newBrushRadius = parseInt(e.key) * 12;
          this.props.setBrushRadius(newBrushRadius);
          break;
        case "Escape":
          this.props.setReclassPoints([]);
          break;
        default:
          break;
      }
    }
  };

  handlePointerDown = (e) => {
    this.props.setActiveEditor(this.props.editorName);

    if (e.button === 0 && this.state.actionMode === ValidationTool.Reclassify &&
      this.props.reclassTool === RECLASS_TOOLS.POLYGON)
    {
      const point = this.getLastPointInPolygon(e, this.state.reclassPoints);
      if (!point) return;

      const newPoints = [...this.state.reclassPoints, point];
      this.props.setReclassPoints(newPoints);

      const plane = this.computeCameraPlane(newPoints[0].point);
      this.reclassPlane = plane;
    }

    const rect = this.container.getBoundingClientRect();
    const mouse = {
      x: ((e.clientX - rect.left) / rect.width) * 2 - 1,
      y: -((e.clientY - rect.top) / rect.height) * 2 + 1,
    };
    this.raycaster.setFromCamera(mouse, this.camera);
  };

  handlePointerUp = (e) => {
    this.props.setActiveEditor(null);

    if (e.button === 0 && this.state.actionMode === ValidationTool.Reclassify) {
      if (this.props.reclassifySource === this.props.reclassifyTarget) return;

      if (this.props.reclassTool === RECLASS_TOOLS.BRUSH) {
        this.setState({
          ...this.state,
          reclassification: {
            loading: true,
            mousePos: {
              x: e.clientX,
              y: e.clientY,
            },
          },
        });
      }
      else if (this.props.reclassTool === RECLASS_TOOLS.POLYGON) {
        // TODO
      }
    }
  };

  handlePointerMove = (e) => {
    this.handlePointerPositionDuringPolygonToolUsage(e);
  };

  handleOnDoubleClick = (e) => {
    if (e.button !== 0 || e.detail !== 2) return;
    
    if (
      this.state.actionMode === ValidationTool.Reclassify &&
      this.props.reclassTool === RECLASS_TOOLS.POLYGON &&
      this.props.reclassPoints.length > 2 &&
      this.props.reclassifySource !== this.props.reclassifyTarget
    ) {
      this.setState({
        ...this.state,
        reclassification: {
          loading: true,
          mousePos: {
            x: null,
            y: null,
          },
        },
      });
    }
  };

  reclassify = (mousePos) => {
    const rect = this.container.getBoundingClientRect();
    const brushOffset = (this.props.brushRadius / rect.width) * 2;
    const mouse = new THREE.Vector2(
      ((mousePos.x - rect.left + this.props.brushRadius) / rect.width) * 2 - 1,
      -((mousePos.y - rect.top + this.props.brushRadius) / rect.height) * 2 + 1
    );

    const brushWorldRadius = this.getBrushWorldRadius(mouse, brushOffset);
    const newGeometry = cloneDeep(this.pointcloud.geometry);
    const classification = newGeometry.getAttribute("classification");
    const positions = newGeometry.getAttribute("position").array;

    const changes = {
      targetClass: this.props.reclassifyTarget,
      indices: [],
    };

    this.raycaster.setFromCamera(mouse, this.camera);

    // Iterate through the indexes of the source points
    for (let i = 0; i < classification.array.length; i++) {
      const pointClass = classification.array[i];
      if (pointClass !== this.props.reclassifySource) continue;

      const point = new THREE.Vector3(
        positions[i * 3],
        positions[i * 3 + 1],
        positions[i * 3 + 2]
      );
      if (this.raycaster.ray.distanceToPoint(point) > brushWorldRadius) {
        continue;
      }

      classification.array[i] = this.props.reclassifyTarget;
      changes.indices.push(i);
    }
    return { geometry: newGeometry, changes };
  };

  reclassifyPolygon = () => {
    const changes = {
      targetClass: this.props.reclassifyTarget,
      indices: [],
    };

    const newGeometry = cloneDeep(this.pointcloud.geometry);
    const classification = newGeometry.getAttribute("classification");
    const positions = newGeometry.getAttribute("position").array;
    const reclassPolyVertices = this.reclassPoly.getAttribute('position').array

    const origo2D = new THREE.Vector3(...reclassPolyVertices.slice(0, 3));
    const pNormal = new THREE.Vector3().copy(origo2D);
    pNormal.add(this.reclassPlane.normal);

    const mRotation = new THREE.Matrix4().lookAt(
      origo2D,
      pNormal,
      new THREE.Vector3(0, 1, 0)
    );

    // Create a new 2D coordinate system in the plane of the polygon
    const plane2D = new THREE.Group();
    this.scene.add(plane2D)
    plane2D.position.copy(origo2D);
    plane2D.quaternion.copy(
      new THREE.Quaternion().setFromRotationMatrix(mRotation)
    );
    plane2D.updateMatrix();

    // Get the inverse of the planes matrix4 - will use it to rebase points
    // into the new coordinate system
    const inverse = new THREE.Matrix4().copy(plane2D.matrix).invert();

    // The polygon's vertices in the new coordinate system
    const polygon2D = []
    for (let i = 0; i < reclassPolyVertices.length; i += 3) {
      const p = new THREE.Vector3(
        reclassPolyVertices[i],
        reclassPolyVertices[i + 1],
        reclassPolyVertices[i + 2]
      );
      p.applyMatrix4(inverse);
      p.set(p.x, p.y, 0);
      polygon2D.push(p);
    }

    let target = new THREE.Vector3();
    for (let i = 0; i < classification.array.length; i++) {
      const pointClass = classification.array[i];
      if (pointClass !== this.props.reclassifySource) continue;

      // Project point to the plane of the polygon
      const point2D = this.reclassPlane.projectPoint(
        new THREE.Vector3(
          positions[i * 3],
          positions[i * 3 + 1],
          positions[i * 3 + 2]
        ),
        target
      );

      // Rebase point vector to the 2D coordinate system of the polygon plane
      point2D.applyMatrix4(inverse);
      point2D.set(point2D.x, point2D.y, 0);
      // If point is in polygon, reclassify
      if (isPointInPolygon(polygon2D, point2D)) {
        classification.array[i] = this.props.reclassifyTarget;
        changes.indices.push(i);
      }
    }

    this.scene.remove(plane2D);
    return { geometry: newGeometry, changes };
  };

  getBrushWorldRadius = (mouse, offset) => {
    const leftPos = { ...mouse, x: mouse.x - offset };
    this.raycaster.setFromCamera(leftPos, this.camera);

    const point1 = new THREE.Vector3();
    point1.addVectors(
      this.raycaster.ray.origin,
      this.raycaster.ray.direction.normalize()
    );

    const rightPos = { ...mouse, x: mouse.x + offset };
    this.raycaster.setFromCamera(rightPos, this.camera);

    const point2 = new THREE.Vector3();
    point2.addVectors(
      this.raycaster.ray.origin,
      this.raycaster.ray.direction.normalize()
    );

    return point1.distanceTo(point2) / 2;
  };

  getMousePoint = (e) => {
    if (e?.detail) return;
    const rect = this.container.getBoundingClientRect();
    const mouse = {
      x: ((e.clientX - rect.left) / rect.width) * 2 - 1,
      y: -((e.clientY - rect.top) / rect.height) * 2 + 1,
    };
    this.raycaster.setFromCamera(mouse, this.camera);

    const intersects = this.raycaster.intersectObjects(this.scene.children);
    const points = intersects.filter(
      (object) => object.object.type === "Points"
    );
    if (points.length <= 0) return;

    const closestPoint = points.reduce((prev, current) =>
      prev.distanceToRay < current.distanceToRay ? prev : current
    );
    return closestPoint;
  };

  getLastPointInPolygon = (event, points) => {
    if (points.length < 3) return this.getMousePoint(event);

    const firstPoint = points[0].point;
    const matchingVectors = points.filter((intersection) =>
      areVectorsEqual(firstPoint, intersection.point, 5)
    );
    if (matchingVectors.length > 1) return null;

    if (!this.polygonEndTarget) return this.getMousePoint(event);

    const lastPoint = points[points.length - 1].point;
    if (lastPoint.equals(this.polygonEndTarget.point)) return null;

    return this.polygonEndTarget;
  };

  handleLineToolStartPointAndPointerIntersectionDetection = (
    pointer,
    points,
    color
  ) => {
    this.polygonEndTarget = null;

    const cameraZoomRange = {
      min: SegmentationMover.minZoom,
      max: SegmentationMover.maxZoom,
    };
    const polygonToolSnapThresholdRange = {
      min: polygonToolMinSnapThreshold,
      max: polygonToolMaxSnapThreshold,
    };
    const raycastThreshold = convertValueBetweenRanges(cameraZoomRange, polygonToolSnapThresholdRange, this.camera.zoom);

    const raycaster = new THREE.Raycaster();
    raycaster.params.Points.threshold = raycastThreshold;
    raycaster.setFromCamera(pointer, this.camera);

    const firstPointPositions = points.slice(0, 3);
    const targetPoint = new THREE.Vector3(...firstPointPositions);

    if (!this.intersectionMarker) {
      this.intersectionMarker = createCircle({ name: "IntersectionMarker" });
      this.scene.add(this.intersectionMarker);
    }

    const targetObject = createTargetPointObject(targetPoint);
    this.scene.add(targetObject);

    const intersects = raycaster.intersectObject(targetObject);

    if (intersects.length > 0) {
      const target = intersects.find(
        (obj) => obj.object.name === targetObject.name
      );
      if (target) {
        const scale = 0.8 / this.camera.zoom;
        this.intersectionMarker.scale.set(scale, scale, scale);
        this.intersectionMarker.position.copy(targetPoint);
        this.intersectionMarker.material.color.setHex(color);
        this.intersectionMarker.rotation.copy(this.camera.rotation);
        this.intersectionMarker.visible = true;
        this.renderObjectOnTop(this.intersectionMarker);
        this.polygonEndTarget = target;
        this.polygonEndTarget.point = targetPoint;
      }
    } else {
      const sphere = this.scene.getObjectByName(this.intersectionMarker.name);
      if (sphere) {
        this.polygonEndTarget = null;
        this.intersectionMarker = null;
        this.scene.remove(sphere);
      }
    }

    this.scene.remove(targetObject);
    this.repaint();
  };

  handlePointerPositionDuringPolygonToolUsage = (event) => {
    if ((this.reclassLineObject && this.state.actionMode === ValidationTool.Reclassify)) {
      const rect = this.container.getBoundingClientRect();
      const pointer = new THREE.Vector2(
        ((event.clientX - rect.left) / rect.width) * 2 - 1,
        -((event.clientY - rect.top) / rect.height) * 2 + 1
      );

      const points = this.reclassLineObject.geometry.attributes.position.array;
      if (points.length < 7) return; // Return until its just a line

      const color = SegmentationMover.reclassifyLineHexcolor;
      this.handleLineToolStartPointAndPointerIntersectionDetection(
        pointer,
        points,
        color
      );
    }
  };

  handlePolygonToolFinishedCleanUp = () => {
    if (this.intersectionMarker) {
      this.scene.remove(this.intersectionMarker);
      this.intersectionMarker = null;
    }

    if (this.polygonEndTarget) this.polygonEndTarget = null;

    if (this.reclassLineObject) {
      this.scene.remove(this.reclassLineObject);
      this.reclassLineObject = null;
    }

    if (this.errorLineObject) {
      this.scene.remove(this.errorLineObject);
      this.errorLineObject = null;
    }

    this.scene.remove(this.scene.getObjectByName("StartPoint"));
  };

  render() {
    let style = {};

    if (this.state.actionMode === ValidationTool.Reclassify) {
      if (this.props.reclassTool === RECLASS_TOOLS.BRUSH) {
        style.cursor = this.state.reclassification.loading
          ? "not-allowed"
          : `url(/images/brush-cursor_${this.props.brushRadius}.svg), pointer`;
      } else {
        style.cursor = this.state.moving ? "grabbing" : "crosshair";
      }
    } else if (this.props.actionMode === ValidationTool.View) {
      style.cursor = this.state.moving ? "grabbing" : "grab";
    }

    return (
      <>
        <div
          className={`tree-mover ${this.props.editorName}`}
          id={this.scene && this.scene.uuid}
          ref={(e) => (this.container = e)}
          onPointerDown={(event) => this.handlePointerDown(event)}
          onPointerMove={(event) => this.handlePointerMove(event)}
          onPointerUp={(event) => this.handlePointerUp(event)}
          onDoubleClick={this.handleOnDoubleClick}
          style={style}
        >
          <canvas ref={(e) => (this.canvas = e)} />
        </div>
      </>
    );
  }
}

export default SegmentationMover;
