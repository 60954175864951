import { useMemo, useState } from 'react';
import Tree from '../../../@types/Tree';
import { PointCloudViewType } from '../../../@types/enums/PointCloudViewType';
import { useScreenContext } from '../../../hooks/betaHooks/semantic-screen-context';
import TabSelector from '../../TabSelector';
import PerspectiveToolbar from '../Toolbars/PerspectiveToolbar';
import SectionToolbar from '../Toolbars/SectionToolbar';
import ToolBarWrapper from '../Toolbars/ToolBarWrapper';
import CanopyView from './CanopyView';
import PanoramaView from './PanoramaView';
import PerspectiveView from './PerspectiveView';
import SectionView from './SectionView';

const VIEW_DROPDOWN_OPTIONS = [
  {
    id: 'Perspective',
    value: PointCloudViewType.PERSPECTIVE,
    label: '',
    iconName: 'pointcloud_360',
  },
  {
    id: 'Top Down',
    value: PointCloudViewType.TOP_DOWN,
    label: '',
    iconName: 'top_down',
  },
  {
    id: 'Section',
    value: PointCloudViewType.SECTION,
    label: '',
    iconName: 'sectionView',
  },
  {
    id: 'Panoramic',
    value: PointCloudViewType.PANORAMIC,
    label: '',
    iconName: 'panorama',
  },
];

type ViewWrapperProps = {
  currentTree: Partial<Tree> | null;
  initialView: PointCloudViewType;
  minZoom?: number;
  maxZoom?: number;
  hideTabSelector?: boolean;
};

const ViewWrapper = ({
  currentTree,
  initialView,
  minZoom,
  maxZoom,
  hideTabSelector,
}: ViewWrapperProps) => {
  const { helper: { isEditingDisabled } } = useScreenContext();
  const [view, setView] = useState<PointCloudViewType>(initialView);

  const viewMap = useMemo(
    () => ({
      Perspective: {
        component: (<PerspectiveView currentTree={currentTree} />),
        toolbar: (
          <ToolBarWrapper>
            <TabSelector
              onSelect={(param) => setView(param.value as PointCloudViewType)}
              tabs={VIEW_DROPDOWN_OPTIONS}
              active={view}
              style={{ zIndex: 1, position: 'absolute' }}
            />
            <PerspectiveToolbar />
          </ToolBarWrapper>
        ),
      },
      'Top Down': {
        component: (<CanopyView currentTree={currentTree} />),
        toolbar: (
          <ToolBarWrapper>
            <TabSelector
              onSelect={(param) => setView(param.value as PointCloudViewType)}
              tabs={VIEW_DROPDOWN_OPTIONS}
              active={view}
              style={{ zIndex: 1, position: 'absolute' }}
            />
          </ToolBarWrapper>
        ),
      },
      Section: {
        component: (
          <SectionView
            currentTree={currentTree}
            minZoom={minZoom}
            maxZoom={maxZoom}
          />
        ),
        toolbar: (
          <ToolBarWrapper>
            <TabSelector
              onSelect={(param) => setView(param.value as PointCloudViewType)}
              tabs={VIEW_DROPDOWN_OPTIONS}
              active={view}
              style={{ zIndex: 1 }}
              hideTabSelector={hideTabSelector}
            />
            <SectionToolbar />
          </ToolBarWrapper>
        ),
      },
      Panoramic: {
        component: <PanoramaView currentTree={currentTree} />,
        toolbar: (
          <ToolBarWrapper>
            <TabSelector
              onSelect={(param) => setView(param.value as PointCloudViewType)}
              tabs={VIEW_DROPDOWN_OPTIONS}
              active={view}
              style={{ zIndex: 1 }}
            />
          </ToolBarWrapper>
        ),
      },
    }),
    [isEditingDisabled, view]
  );

  return (
    <div style={{ display: 'flex', flex: 1, position: 'relative', overflow: 'visible' }}>
      {viewMap[view].toolbar && viewMap[view].toolbar}
      {viewMap[view].component}
    </div>
  );
};

export default ViewWrapper;
