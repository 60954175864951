import { Provider as ThemeProvider } from './theme';
import { Provider as ModalProvider } from './modal';
import { Provider as PopupProvider } from './alert';
import { Provider as ConfigProvider } from './config';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './auth-provider';
import { UserProvider } from './user-provider';

const Providers = ({ children }) => {
  return (
    <>
      <AuthProvider>
      <UserProvider>
      <ThemeProvider>
      <ConfigProvider>
      <ModalProvider>
      <PopupProvider>
        {children}
      </PopupProvider>
      </ModalProvider>
      </ConfigProvider>
      </ThemeProvider>
      </UserProvider>
      </AuthProvider>

      <Toaster />
    </>
  );
};

export default Providers;
