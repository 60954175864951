import { useCallback, useEffect, useState } from 'react';
import { MicroclimateParams } from '../../@types/MicroclimateValidation';
import Tree from '../../@types/Tree';
import { getProjectYear } from '../../core/projectSelector/projectSelector';
import TreeFlowStatus from '../../@types/enums/TreeFlowStatus';
import TreeStatus from '../../@types/enums/TreeStatus';
import useApi from '../api';
import { Girth, TrunkWithGirths } from '../../@types/Girth';

const transformGirthToTrunks = (girths: Girth[]) => {
  const [girth1, girth2] = girths;

  return {
    ...(girth1 && {
      trunkOffsetX1: girth1.dX,
      trunkOffsetY1: girth1.dY,
      trunkEllipseA1: girth1.rX,
      trunkEllipseB1: girth1.rY,
      trunkEllipseDirection1: girth1.rotation,
    }),
    ...(girth2 && {
      measurementHeight2: girth2.height,
      normalVector2: [girth2.normal.x, girth2.normal.y, girth2.normal.z],
      girth2: girth2.diameter,
      trunkOffsetX2: girth2.dX,
      trunkOffsetY2: girth2.dY,
      trunkEllipseA2: girth2.rX,
      trunkEllipseB2: girth2.rY,
      trunkEllipseDirection2: girth2.rotation,
    }),
    girth: girth1?.diameter ?? 0,
    girth1: girth1?.diameter ?? 0, // showing the (first) girths are based on girth1 value. setting them to 0 is needed when they are removed
    measurementHeight1: girth1?.height ?? 0, // required not to be empty
    normalVector1: girth1 ? [girth1.normal.x, girth1.normal.y, girth1.normal.z] : [0, 0, 0],
    validated: girth1?.validated ?? false,
  };
};

export enum ScreenTypes {
  TASK_OVERVIEW = 'task_overview',
  VALIDATION = 'validation',
  MICROCLIMATE_DATA_INPUT = 'microclimate_data_input',
  QUALITY_CHECK = 'quality_check',
}

// TODO: In tha future we would like to refactor this, because currently we are creating notes as comments
export enum CommentTypes {
  COMMENT = 'comment',
  Note = 'note',
}

export interface Comment {
  message: string;
  comment_type: CommentTypes;
  isUndoAction: boolean;
}

export interface ManagedArea {
  id: string;
  code: string;
}

export type MarkAsDoneLocationOptions = Pick<Tree, 'location' | 'manual_treelocation_changes'>;

export type MarkAsCompletedSemanticOptions = Pick<
  Tree,
  | 'girth_1_0m'
  | 'girth_1_0m_offset_x'
  | 'girth_1_0m_offset_y'
  | 'girth_1_0m_ellipse_a'
  | 'girth_1_0m_ellipse_b'
  | 'girth_1_0m_ellipse_direction'
  | 'are_girth_axes_valid'
  | 'height'
  | 'crown_height'
  | 'trunk_height'
  | 'canopy_ellipse_a'
  | 'canopy_ellipse_b'
  | 'canopy_ellipse_direction'
  | 'crown_excentricity'
  | 'first_bifurcation_point'
  | 'status'
  | 'trunks'
  | 'tree_trunks'
>;

export interface GetGenusesOptions {
  /** Filter genuses by their name. Partial matches will be returned. */
  name: string;
  /** The response will only contain the names of the genuses. */
  onlyName?: boolean;
}
export interface GetSpeciesOptions {
  /** Filter species by their name. Partial matches will be returned. */
  name: string;
  /** Filter species based on the genus they belong to. */
  genusId?: number;
  /** The response will only contain the names of the species. */
  onlyName?: boolean;
}

export type GetCapturePointOptions = Pick<Tree, 'location'> & {
  locationParsed: GeoJSON.Point;
};
export interface BetaTreeContextValue {
  error: any | null;
  loading: boolean;
  tree: Partial<Tree> | null;
  fetch: () => Promise<any | null>;
  reset: () => Promise<void>;
  setTree: React.Dispatch<React.SetStateAction<Partial<Tree> | null>>;
  createTree: (longitude: string, latitude: string, SRID: string) => Promise<Partial<Tree> | null>;
  deleteTreeLocation: () => Promise<any>;
  deleteTreeSegmentation: () => Promise<any>;
  addComment: (commentProps: Comment) => Promise<Comment | null>;
  addCommentWithTreeId: (commentProps: Comment, treeId: string) => Promise<Comment | null>;
  removeComment: (commentId: string) => Promise<string | null>;
  updateTmsCategory: (category: string) => Promise<boolean>;
  markAsDoneLocation: (options: MarkAsDoneLocationOptions) => Promise<any>;
  markAsDoneSegmentation: () => Promise<any>;
  markAsCompletedSemantic: (options: MarkAsCompletedSemanticOptions) => Promise<any>;
  updateMicroclimate: (options: MicroclimateParams) => Promise<any>;
  getGenuses: (options: GetGenusesOptions) => Promise<any[]>;
  getSpecies: (options: GetSpeciesOptions) => Promise<any[]>;
  setGenusAndSpecies: (genusId: number | null, speciesId?: number | null) => Promise<any>;
  sendToAnnotation: () => Promise<any>;
  updateTree: (options: any, logUser?: boolean) => Promise<any>;
  updateTreeWithId: (id: string, options: any, logUser?: boolean) => Promise<any>;
  syncTrunk: (data: any) => Promise<any>;
  saveLaz: (...args: any[]) => Promise<any>;
  addTree: (lng: any, lat: any, managedAreaCode: string, managedAreaId: string, pipelineId: string, payload: any) => Promise<any>;
  setQualityCheckStatus(
    treeId: string,
    qualityCheckStatus: string,
    treeAttributes?: Partial<Tree>,
    issues?: string[],
  ): Promise<any>;
}

export interface UseBetaTreeProps {
  treeId?: string;
  managedAreaId: string;
  managedAreaCode: string;
  ignoreAutoPull?: boolean;
  isMicroclimate?: boolean;
  isQualityCheck?: boolean;
}

export const useBetaTree = (props: UseBetaTreeProps): BetaTreeContextValue => {
  const { handleRequest } = useApi();

  const [loading, setLoading] = useState<BetaTreeContextValue['loading']>(false);
  const [error, setError] = useState<BetaTreeContextValue['error']>(null);
  const [tree, setTree] = useState<BetaTreeContextValue['tree']>(null);

  const scanYear = getProjectYear();
  const queryYear = !!scanYear ? `scanYear=${scanYear}` : 'latest=1';

  const fetch = useCallback(async () => {
    if (!props?.treeId) {
      console.warn('BetaTreeContextProvider has no Tree defined.');
      return;
    }

    if (!props?.managedAreaId) {
      console.warn('BetaTreeContextProvider has no ManagedAreaId defined.');
      return;
    }

    if (!props?.managedAreaCode) {
      console.warn('BetaTreeContextProvider has no ManagedAreaCode defined.');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const treesResponse = await handleRequest(
        `v1/trees?mas=${props.managedAreaId}&id=${props.treeId}${
          props.isMicroclimate ? '&vs=microclimate' : ''
        }&q=${new Date().getTime()}&${queryYear}`
      ).then((response) => response.json());

      setTree(treesResponse[0]);

      return treesResponse[0];
    } catch (e) {
      setError(e as any);
    } finally {
      setLoading(false);
    }
  }, [
    handleRequest, props.treeId, props.managedAreaId, props.managedAreaCode,
    props.isMicroclimate, queryYear
  ]);

  const reset = useCallback(async () => {
    setTree(null);
    setLoading(false);
    setError(null);
  }, []);

  const createTree = useCallback(
    async (longitude: string, latitude: string, SRID: string) => {
      const createTreeResponse = await handleRequest(
        `/v1/trees/${longitude},${latitude},${SRID}?` +
          `managed_area=${props.managedAreaCode}&` +
          `managed_area_id=${props.managedAreaId}&` +
          `q=${new Date().getTime()}&${queryYear}`,
        { method: 'POST' }
      ).then((response) => response.json());

      setTree(createTreeResponse[0]);

      await fetch();

      return createTreeResponse;
    },
    [handleRequest, props.managedAreaCode, props.managedAreaId, queryYear, setTree, fetch]
  );

  const deleteTreeLocation = useCallback(async () => {
    const statusToUpdate = TreeStatus.LocationValidationDeleted;
    const treeFlowStatusToUpdate = TreeFlowStatus.LocationValidationDeleted;

    const deleteTreeResponse = await handleRequest(`/v1/trees/update?id=${props.treeId}&${queryYear}`, {
      method: 'PATCH',
      body: JSON.stringify({
        status: statusToUpdate,
        tree_flow_status: treeFlowStatusToUpdate,
      }),
      headers: { 'Content-Type': 'application/json' },
    }).then((response) => response.json());

    await fetch();

    return deleteTreeResponse;
  }, [handleRequest, fetch, props.treeId, queryYear]);

  const deleteTreeSegmentation = useCallback(async () => {
    const statusToUpdate = TreeStatus.SegmentationValidationDeleted;
    const treeFlowStatusToUpdate = TreeFlowStatus.SegmentationValidationDeleted;

    const deleteTreeResponse = await handleRequest(`/v1/trees/update?id=${props.treeId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        status: statusToUpdate,
        tree_flow_status: treeFlowStatusToUpdate,
      }),
      headers: { 'Content-Type': 'application/json' },
    }).then((response) => response.json());

    await fetch();

    return deleteTreeResponse;
  }, [handleRequest, props.treeId, fetch]);

  const addComment = useCallback(
    async (comment: Comment) => {
      const addCommentResponse = await handleRequest(`/v1/add-tree-comment?id=${props.treeId}`, {
        method: 'POST',
        body: JSON.stringify({
          message: comment.message,
          comment_type: comment.comment_type,
          isUndoAction: comment.isUndoAction,
        }),
        headers: { 'Content-Type': 'application/json' },
      }).then((response) => response.json());

      await fetch();

      return addCommentResponse;
    },
    [handleRequest, props.treeId, fetch]
  );

  const addCommentWithTreeId = useCallback(
    async (comment: Comment, treeId: string) => {
      const addCommentResponse = await handleRequest(`/v1/add-tree-comment?id=${treeId}`, {
        method: 'POST',
        body: JSON.stringify({
          message: comment.message,
          comment_type: comment.comment_type,
          isUndoAction: comment.isUndoAction,
        }),
        headers: { 'Content-Type': 'application/json' },
      }).then((response) => response.json());

      await fetch();

      return addCommentResponse;
    },
    [handleRequest, fetch]
  );

  const removeComment = useCallback(
    async (commentId: string) => {
      try {
        const removeCommentResponse = await handleRequest(`/v1/comments/${commentId}`, {
          method: 'DELETE',
        }).then((response) => response.json());

        await fetch();

        return removeCommentResponse;
      } catch (e) {
        //
      }
    },
    [handleRequest, fetch]
  );

  const updateTmsCategory = useCallback(
    async (tmsCategory: string) => {
      const response = (await handleRequest(`/v1/trees/update?id=${props.treeId}`, {
        method: 'PATCH',
        body: JSON.stringify({
          tms_category: tmsCategory,
        }),
        headers: { 'Content-Type': 'application/json' },
      })) as Response;
      return response.ok ? true : false;
    },
    [props.treeId, handleRequest]
  );

  const markAsDoneLocation = useCallback(
    async (options: MarkAsDoneLocationOptions) => {
      const requestParams = {
        ...options,
        status: TreeStatus.LocationValidationDone,
        tree_flow_status: TreeFlowStatus.LocationValidationDone,
      };
      const markAsDoneResponse = await handleRequest(`/v1/trees/update?id=${props.treeId}`, {
        method: 'PATCH',
        body: JSON.stringify(requestParams),
        headers: { 'Content-Type': 'application/json' },
      }).then((response) => response.json());

      await fetch();

      return markAsDoneResponse;
    },
    [handleRequest, props.treeId, fetch]
  );

  const markAsDoneSegmentation = useCallback(async () => {
    const requestParams = {
      status: TreeStatus.SegmentationValidationDone,
      tree_flow_status: TreeFlowStatus.SegmentationValidationDone,
    };

    if (
      tree?.tree_flow_status === TreeFlowStatus.SentToOnlineAnnotationQueued ||
      tree?.tree_flow_status === TreeFlowStatus.SentToOnlineAnnotationRunning
    ) {
      requestParams.tree_flow_status = TreeFlowStatus.SentToOnlineAnnotationDone;
    }

    const markAsDoneResponse = await handleRequest(`/v1/trees/update?id=${props.treeId}`, {
      method: 'PATCH',
      body: JSON.stringify(requestParams),
      headers: { 'Content-Type': 'application/json' },
    }).then((response) => response.json());

    await fetch();

    return markAsDoneResponse;
  }, [handleRequest, props.treeId, fetch, tree?.tree_flow_status]);

  const syncTrunk = useCallback(
    async (trunk: TrunkWithGirths) => {
      try {
        const girthsWithHeights = trunk.girths.map((girth: any) => ({ ...girth, height: girth.height }));
        const transformedTrunk = transformGirthToTrunks(girthsWithHeights);
        // PATCH - Update existing trunk
        if (trunk.id) {
          const upsertTrunkDetailsResponse = await handleRequest(`/trees/${props.treeId}/trunks`, {
            method: 'PATCH',
            body: JSON.stringify({
              id: trunk.id,
              treeId: props.treeId,
              ...transformedTrunk,
            }),
            headers: { 'Content-Type': 'application/json' },
          });

          return upsertTrunkDetailsResponse.json();
        }

        // POST - Create new trunk
        const createTrunkResponse = await handleRequest(`/trees/${props.treeId}/trunks`, {
          method: 'POST',
          body: JSON.stringify({
            treeId: props.treeId,
            ...transformedTrunk,
          }),
          headers: { 'Content-Type': 'application/json' },
        });

        return createTrunkResponse.json();
      } catch (e) {
        console.log(e);

        return {
          error: e,
          status: 500,
        };
      }
    },
    [handleRequest, props.treeId]
  );

  const markAsCompletedSemantic = useCallback(
    async (options: MarkAsCompletedSemanticOptions) => {
      console.log('We cannot use this, because SemVal screen works different. We need to use updateTree instead');
    },
    []
  );

  const updateTreeWithId = useCallback(
    async (id: string, options: any, logUser?: boolean) => {
      const logUserString = logUser !== undefined ? `&log_user=${logUser}` : '';
      const isQualityCheckParam = props?.isQualityCheck ? '&isQualityCheck=true' : ''
      await handleRequest(`/v1/trees/update?id=${id}${logUserString}${isQualityCheckParam}`, {
        method: 'PATCH',
        body: JSON.stringify(options),
        headers: { 'Content-Type': 'application/json' },
      });
    },
    [handleRequest]
  );

  const updateTree = useCallback(
    async (options: any, logUser?: boolean) => {
      if (!props?.treeId) return;
      await updateTreeWithId(props.treeId, options, logUser);
      await fetch();
    },
    [props.treeId, fetch, updateTreeWithId]
  );

  const updateMicroclimate = useCallback(
    async (options: MicroclimateParams) => {
      await handleRequest(`/v1/microclimate?id=${props.treeId}`, {
        method: 'PATCH',
        body: JSON.stringify(options),
        headers: { 'Content-Type': 'application/json' },
      });
      await fetch();
    },
    [handleRequest, props.treeId, fetch]
  );

  const setGenusAndSpecies = useCallback(
    async (genusId: number | null, speciesId?: number | null) => {
      // If the genus or species of a tree is manually changed, we set the confidence levels automatically to 100%.
      const options: any = {
        genus: genusId,
        genus_confidence: 1,
      };
      if (speciesId !== undefined) {
        options.species = speciesId;
        options.species_confidence = 1;
      }

      await updateTree(options);
    },
    [updateTree]
  );

  const sendToAnnotation = useCallback(async () => {
    const sendToAnnotationResponse = await handleRequest(`/v1/trees/update?id=${props.treeId}`, {
      method: 'PATCH',
      body: JSON.stringify({
        status: TreeStatus.SegmentationValidationDone,
        tree_flow_status: TreeFlowStatus.SegmentationValidationDone,
      }),
      headers: { 'Content-Type': 'application/json' },
    }).then((response) => response.json());

    await fetch();

    return sendToAnnotationResponse;
  }, [handleRequest, props.treeId, fetch]);

  const saveLaz = useCallback(
    async (payload: any) => {
      const response = await handleRequest(`/v1/trees/las`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' },
      }).then((response) => response.json());
      if (response.result !== "OK") {
        throw new Error(`${response.statusCode}: ${response.message}`)
      }
    },
    [handleRequest]
  );

  const addTree = useCallback(
    async (lng: any, lat: any, managedAreaCode: string, managedAreaId: string, pipelineId: string) => {
      console.log('adding tree', lng, lat, managedAreaCode, managedAreaId, pipelineId);
      return await handleRequest(
        `/v1/trees/${lng},${lat},4326?managed_area=${managedAreaCode}&managed_area_id=${managedAreaId}&pipeline_id=${pipelineId}&q=${new Date().getTime()}`,
        {
          method: 'POST',
        }
      )
        .then((response) => response.json())
        .then((item) => item?.[0]);
    },
    [handleRequest]
  );

  const getGenuses = useCallback(
    async (options: GetGenusesOptions): Promise<any[]> => {
      let queryString = `/v1/genuses?name=${options.name}`;
      if (options.onlyName) {
        queryString += `&only_name=${options.onlyName}`;
      }
      return await handleRequest(queryString)
        .then((response) => response.json())
        .catch(() => []);
    },
    [handleRequest]
  );

  const getSpecies = useCallback(
    async (options: GetSpeciesOptions): Promise<any[]> => {
      let queryString = `/v1/species?name=${options.name}`;
      if (options.genusId) {
        queryString += `&genus_id=${options.genusId}`;
      }
      if (options.onlyName) {
        queryString += `&only_name=${options.onlyName}`;
      }
      return await handleRequest(queryString)
        .then((response) => response.json())
        .catch(() => []);
    },
    [handleRequest]
  );

  const setQualityCheckStatus = useCallback(
    async (
      treeId: string,
      qc_status: string,
      treeAttributes?: Partial<Tree>,
      issues: string[] = [],
    ): Promise<any[]> => {
      const qualityCheckStatusResponse = await handleRequest(`/v1/quality_check/upsert`, {
        method: 'PATCH',
        body: JSON.stringify({ qc_status, issues: issues, treeIds: [treeId] }),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => response.json())
        .catch(() => []);

      let treeUpdateResponse;

      if (treeAttributes) {
        treeUpdateResponse = await updateTreeWithId(treeId, treeAttributes, true);
        await fetch();
      }
      

      return [qualityCheckStatusResponse, treeUpdateResponse];
    },
    [handleRequest, fetch, updateTreeWithId]
  );

  useEffect(() => {
    if (props.ignoreAutoPull) return;
    if (!props.treeId) return;
    if (!props.managedAreaId) return;
    if (!props.managedAreaCode) return;

    fetch();
  }, [props.ignoreAutoPull, props.treeId, props.managedAreaId, props.managedAreaCode]);

  return {
    error,
    loading,
    tree,

    fetch,
    reset,

    setTree,

    getGenuses,
    getSpecies,

    createTree,
    deleteTreeLocation,
    deleteTreeSegmentation,
    addComment,
    removeComment,
    updateTmsCategory,
    markAsDoneLocation,
    markAsDoneSegmentation,
    markAsCompletedSemantic,
    updateMicroclimate,
    setGenusAndSpecies,
    sendToAnnotation,
    addCommentWithTreeId,
    saveLaz,
    addTree,

    updateTree,
    updateTreeWithId,
    syncTrunk,
    setQualityCheckStatus,
  };
};
