import { useState, useEffect, useCallback } from 'react';
import useApi from '../hooks/api';

export const getVoxel = async (tree) => {
  if (!tree) {
    return null;
  }

  try {
    const res = await fetch(tree.voxel_url);

    if (res.status !== 200) {
      throw new Error();
    } else {
      return await res.json();
    }
  } catch (e) {
    return null;
  }
};

export const useGlobalConfig = () => {
  const [globalConfig, setGlobalConfig] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const { handleRequest } = useApi();

  useEffect(() => {
    const _handleLoad = async () => {
      setLoaded(false);

      try {
        const res = await handleRequest(`/v1/config?q=${new Date().getTime()}`).then((res) => res.json());

        setGlobalConfig(res);
        setLoaded(true);
      } catch (error) {
        console.warn('FAILED TO LOAD GLOBAL CONFIG: ', error);
      }
    };

    _handleLoad();
  }, [handleRequest]);

  const updateGlobalConfig = (newConfig) => {
    const _handleUpdate = async () => {
      setLoaded(false);

      try {
        const res = await handleRequest(`/v1/config?q=${new Date().getTime()}`, {
          method: 'PATCH',
          body: JSON.stringify(newConfig),
          headers: { 'Content-Type': 'application/json' },
        });

        if (res.status !== 200) {
          throw new Error();
        }
      } catch (error) {
        console.warn('FAILED TO SAVE GLOBAL CONFIG: ', error);
      }

      setLoaded(true);
      setGlobalConfig(newConfig);
    };

    _handleUpdate();
  };

  const getGlobalConfig = useCallback((name) => globalConfig?.find?.((config) => config.config_name === name)?.value, [globalConfig]);

  return { globalConfig, loaded, updateGlobalConfig, getGlobalConfig };
};
