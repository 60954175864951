import { Canvas } from '@react-three/fiber';
import { Suspense, useEffect, useMemo, useRef } from 'react';
import { Color, Group, MathUtils, Mesh, Vector3 } from 'three';
import ValidationAction from '../../../@types/enums/ValidationAction';
import { convertValueBetweenRanges } from '../../../utils/mathUtils';
import { AxesHelper } from '../../ThreeHelperObjects/HelperObjects';
import DragPoint from '../Gizmos/DragPoint';
import HeightGizmo from '../Gizmos/HeightGizmo';
import SemanticActionsColorMap from '../SemanticActionsColorMap';
import TreePointCloud from '../TreePointCloud/TreePointCloud';
import ValidationControls from '../ValidationControls/ValidationControls';
import ViewBackground from '../ViewBackground/ViewBackground';
import config from '../config';
import { useSemanticScreenContext } from '../../../hooks/betaHooks/semantic-screen-context';
import Tree from '../../../@types/Tree';
import LeanGizmo from '../Gizmos/LeanGizmo';

const MIN_MAX_ZOOM = {
  min: 20,
  max: 500,
};

type PerspectiveViewProps = {
  currentTree: Partial<Tree> | null;
};

const PerspectiveView = ({ currentTree }: PerspectiveViewProps) => {
  const {
    treeMetrics,
    visibility,
    pointCloudModule: { pointCloud, environmentPointCloud, isEnvironmentVisible, pointSize },
    section,
    validation,
    actions,
    helper,
  } = useSemanticScreenContext();

  const sceneRef = useRef<HTMLCanvasElement>(null);

  const fasz = useRef(0);

  const scaledInitialZoom = useMemo(() => {
    const boundingDiameter = pointCloud?.geometry.boundingSphere?.radius ? pointCloud?.geometry.boundingSphere?.radius * 2 : 1;
    const scaledZoom = convertValueBetweenRanges(
      { min: 0, max: boundingDiameter + 1 },
      { min: MIN_MAX_ZOOM.min, max: MIN_MAX_ZOOM.max },
      boundingDiameter
    );
    return MathUtils.clamp(scaledZoom, MIN_MAX_ZOOM.min, MIN_MAX_ZOOM.max) * 0.8;
  }, [pointCloud?.geometry.boundingSphere?.radius]);

  const localHeight = currentTree?.location_local ? currentTree.location_local[2] : 0;

  function onFirstBifurcationHeightChange(value: number) {
    const correctedHeight = treeMetrics.firstBifurcationPoint.z - helper.viewerPosition.z - localHeight;
    const correctedTarget = new Vector3(0, 0, correctedHeight);

    const newFirstBifurcationPoint = treeMetrics.firstBifurcationPoint.clone();
    newFirstBifurcationPoint.z = value + localHeight;
    treeMetrics.setFirstBifurcationPoint(newFirstBifurcationPoint);
    section.setDefaultTarget(correctedTarget);
    section.setTarget(correctedTarget);
    section.setDefaultNormal(new Vector3(0, 0, 1));
    section.setNormal(new Vector3(0, 0, 1));
  }

  if (!currentTree?.id) return null;

  return (
    <>
      <Canvas
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
        orthographic
        linear
        gl={{ sortObjects: true }}
        camera={{
          near: config.nearPlane,
          up: new Vector3(0, 0, 1),
          far: config.farPlane,
          zoom: scaledInitialZoom,
        }}
        ref={sceneRef}
      >
        <ViewBackground />
        {config.isAxesHelperVisible && <AxesHelper size={15} />}

          <group position={new Vector3()}>
            <TreePointCloud
              treePointCloud={pointCloud}
              envPointCloud={environmentPointCloud}
              isEnvVisible={isEnvironmentVisible}
              pointSize={pointSize}
              locationDelta={helper.locationDelta}
            />

            <DragPoint
              name={'tree-location-point'}
              initialPosition={new Vector3()}
              color={new Color('red')}
              draggable={false}
              visibility={true}
              handleSize={0.1}
            />

            <HeightGizmo
              name={ValidationAction.Height}
              color={SemanticActionsColorMap[ValidationAction.Height]}
              positionZ={treeMetrics.height ?? 0}
              visible={visibility.height}
              draggable={!helper.isEditingDisabled && !validation.isTreeHeightCompleted}
              activeTool={helper.activeTool as string}
              onGrabbed={(name) => {
                actions.setActiveTool(name as ValidationAction);
              }}
              onChange={(z: number) => treeMetrics.setHeight(z)}
            />
            <HeightGizmo
              allowBelowZeroZ={true}
              name={ValidationAction.CanopyHeight}
              color={SemanticActionsColorMap[ValidationAction.CanopyHeight]}
              positionZ={treeMetrics.height - treeMetrics.canopyHeight}
              visible={visibility.canopyHeight}
              draggable={!helper.isEditingDisabled && !validation.isCrownHeightCompleted}
              activeTool={helper.activeTool as string}
              onGrabbed={(name) => {
                actions.setActiveTool(name as ValidationAction);
              }}
              onChange={(z: number) => treeMetrics.setCanopyHeight(treeMetrics.height - z)}
            />
            <HeightGizmo
              name={ValidationAction.FirstBifurcationPoint}
              color={SemanticActionsColorMap[ValidationAction.FirstBifurcationPoint]}
              positionZ={treeMetrics.firstBifurcationPoint.z - localHeight || 0}
              activeTool={helper.activeTool as string}
              visible={visibility.firstBifurcationPoint}
              draggable={!helper.isEditingDisabled && !validation.isFirstBifurcationCompleted}
              onGrabbed={(name) => {
                if (name && helper.activeTool !== ValidationAction.FirstBifurcationPoint) {
                  actions.setActiveTool(name as ValidationAction);
                  section.setTarget(section.defaultTarget!);
                  section.setNormal(section.defaultNormal!);
                }
              }}
              onChange={onFirstBifurcationHeightChange}
            />
            <HeightGizmo
              name={'section-height-helper'}
              color='#FFFFFF'
              positionZ={section.target?.z ?? 0}
              draggable={false}
              depth={section.depth}
              visible={section.visibility}
              viewerPosition={helper.locationDelta}
            />
            <LeanGizmo handleSize={0.1} />
          </group>

        <ValidationControls
          minPolarAngle={Math.PI / 2}
          maxPolarAngle={Math.PI / 2}
          minZoom={MIN_MAX_ZOOM.min}
          maxZoom={MIN_MAX_ZOOM.max}
          name='height'
          treeId={currentTree.id}
        />
      </Canvas>
    </>
  );
};

export default PerspectiveView;
