import { PureComponent } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three-stdlib';
import renderGrid from './renderGrid';
import TreeFlowStatus from '../../@types/enums/TreeFlowStatus';

const treeStatusToColorMap = {
  [TreeFlowStatus.LocationValidationQueued]: 0xff0000,
  [TreeFlowStatus.LocationValidationDeleted]: 0x666666,
  [TreeFlowStatus.LocationValidationDone]: '#AFFF14',
  [TreeFlowStatus.SentToField]: 0xffff00
};

class MovableTree extends PureComponent {
  constructor(props) {
    super(props);

    this.repaintRequestPending = false;

    this.plane = new THREE.Plane();
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.intersection = new THREE.Vector3();

    this.scene = new THREE.Scene();
    this.scene.up.set(0, 0, 1);
    this.scene.background = new THREE.Color(this.props.background || 0x000000);

    this.axesHelper = new THREE.AxesHelper(5);
    this.axesHelper.visible = false;
    this.scene.add(this.axesHelper);

    const geometry = new THREE.SphereGeometry(MovableTree.sphereSize);
    const material = new THREE.MeshBasicMaterial({ color: MovableTree.sphereColor });
    if (this.props.isUp) {
      material.transparent = true;
      material.opacity = 0.9;
    }
    this.sphere = new THREE.Mesh(
      geometry,
      material,
    );
    this.scene.add(this.sphere);

    this.state = {
      isControlMoving: false,
      isMouseDown: false,
      isMouseOverControl: false
    };
  }

  static frustumSize = 8;
  static sphereSize = 0.2;
  static sphereColor = 0x0000ff;
  static sphereColorHover = 0x00ffff;
  static minZoom = 0.2;
  static maxZoom = 7;
  static targetDistance = 100;

  componentDidMount = () => {
    const resizeObserver = new ResizeObserver((entries) => {
      const borderBoxSize = entries[0]?.borderBoxSize[0];
      if (!borderBoxSize) return;

      this.resizeViewport(borderBoxSize.inlineSize, borderBoxSize.blockSize);
    });
    resizeObserver.observe(this.container);

    this.camera = new THREE.OrthographicCamera(
      -0.5 * MovableTree.frustumSize * 5/2,
      0.5 * MovableTree.frustumSize * 5/2,
      0.5 * MovableTree.frustumSize,
      -0.5 * MovableTree.frustumSize,
      0.1,
      1000
    );
    this.camera.up.set(0, 0, 1);
    this.camera.updateProjectionMatrix();

    this.renderer = new THREE.WebGLRenderer({ antialias: true, canvas: this.canvas });
    this.renderer.setPixelRatio(window.devicePixelRatio);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enablePan = true;
    this.controls.rotateSpeed = 2.0;
    this.controls.minZoom = MovableTree.minZoom;
    this.controls.maxZoom = MovableTree.maxZoom;
    this.controls.minDistance = MovableTree.targetDistance;
    this.controls.maxDistance = MovableTree.targetDistance;
    this.controls.enableRotate = true;
    this.controls.setAzimuthalAngle(0);
    if (this.props.isUp) {
      this.controls.minPolarAngle = 0;
      this.controls.maxPolarAngle = 0;
    } else {
      this.controls.minPolarAngle = Math.PI / 2;
      this.controls.maxPolarAngle = Math.PI / 2;
    }
    this.props.viewControls.current.push(this.controls);
    this.controls.addEventListener('change', () => {
      if (this.props.name === this.props.activeMoverName) {
        for (const otherControls of this.props.viewControls.current) {
          const azimuthAngle = this.controls.getAzimuthalAngle();
          if (otherControls !== this.controls) {
            otherControls.setAzimuthalAngle(azimuthAngle);
          }
        }
      }
      this.repaint();
    });
    this.controls.update();

    this.updateFromProps({});
  }

  componentWillUnmount = () => {
    this.props.viewControls.current = this.props.viewControls.current.filter(c => c !== this.controls);
  }

  resizeViewport = (width, height) => {
    if (!this.container) return;

    this.aspectRatio = width / height;
    this.camera.left = -0.5 * MovableTree.frustumSize * this.aspectRatio;
    this.camera.right = 0.5 * MovableTree.frustumSize * this.aspectRatio;
    this.camera.top = 0.5 * MovableTree.frustumSize;
    this.camera.bottom = -0.5 * MovableTree.frustumSize;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
    this.renderer.render(this.scene, this.camera);
  };

  updateFromProps = (prevProps) => {
    this.sphere.position.copy(this.props.position);
    if (this.props.isUp) {
      this.sphere.position.z = MovableTree.targetDistance;
    }
    this.axesHelper.position.copy(this.sphere.position);


    if (prevProps.background !== this.props.background) {
      this.scene.background = new THREE.Color(this.props.background || 0x000000);
    }

    if (prevProps.isGridEnabled !== this.props.isGridEnabled && this.props.name === 'side-view') {
      if (this.props.isGridEnabled) {
        const grid = renderGrid(this.scene, this.props.position);
        this.removeGrid = grid.remove;
        this.syncGridRotation = grid.syncRotation;
        this.syncGridPosition = grid.syncPosition;
      } else if (this.removeGrid) {
        this.removeGrid();
      }
    }

    if (prevProps.position !== this.props.position) {
      if (this.props.isGridEnabled) {
        this.syncGridPosition(this.props.position);
      }
    }

    if (prevProps.pointcloud !== this.props.pointcloud || (!prevProps.position && this.props.position)) {
      if (this.points) {
        this.points.geometry.dispose();
        this.points.material.dispose();
        this.scene.remove(this.points);
      }

      // pointcloud can be false
      if (this.props.pointcloud) {
        this.pointcloud = this.props.pointcloud;

        const points = new THREE.Points(this.props.pointcloud.geometry, this.props.pointcloud.createMaterial());
        this.points = points;
        this.points.up.set(0, 0, 1);

        this.scene.add(this.points);

        const visibilities = new Float32Array(this.pointcloud.geometry.attributes.color.count).fill(1);
        this.pointcloud.geometry.setAttribute('visibility', new THREE.Float32BufferAttribute(visibilities, 1));
      }

      if (this.controls) {
        this.controls.target.set(
          this.sphere.position.x,
          this.sphere.position.y,
          this.sphere.position.z + 2,
        );
        // For some reason, OrbitControls needs two separate updates for target and 
        // angle, otherwise the angle will not change.
        this.controls.update();
        this.controls.setAzimuthalAngle(0);
        this.controls.update();
      }

      this.axesHelper.position.copy(this.sphere.position);

      if (this.props.closeByTreePoints !== prevProps.closeByTreePoints) {
        this.scene.children.filter(child => child.name === 'close-by-tree-point').forEach(child => this.scene.remove(child));
        this.closeByTreePoints = this.props.closeByTreePoints?.map((point) => {
          const geometry = new THREE.SphereGeometry(MovableTree.sphereSize, 16, 16);
          const material = new THREE.MeshBasicMaterial({ color: treeStatusToColorMap[point.tree_flow_status] });
          const sphere = new THREE.Mesh(geometry, material);
          sphere.position.copy(point.coords);
          sphere.name = 'close-by-tree-point';
          this.scene.add(sphere);
          return sphere;
        });
      }
    }

    this.repaint();
  }

  componentDidUpdate = prevProps => this.updateFromProps(prevProps)

  repaint = () => {
    if (!this.renderer) return;

    if (this.syncGridRotation) {
      this.syncGridRotation(this.camera);
    }
    if (this.syncGridPosition) {
      this.syncGridPosition(this.props.position, this.camera);
    }
    const treeLocationPointScale = 1 / this.camera.zoom;
    this.sphere.scale.set(treeLocationPointScale, treeLocationPointScale, treeLocationPointScale);
    if (this.closeByTreePoints) {
      const closebyTreePointScale = treeLocationPointScale / 2;
      this.closeByTreePoints.forEach(p => p.scale.set(closebyTreePointScale, closebyTreePointScale, closebyTreePointScale));
    }

    if (this.repaintRequestPending) return;

    this.repaintRequestPending = true;
    requestAnimationFrame(() => {
      this.renderer.render(this.scene, this.camera);
      this.repaintRequestPending = false;
    });
  };

  handlePointerDown = (e) => {
    e.preventDefault();

    this.props.setActiveMoverName(this.props.name);

    const target = new THREE.Vector3();
    this.camera.getWorldDirection(target);
    this.plane.setFromNormalAndCoplanarPoint(
      this.props.isUp ? new THREE.Vector3(0, 0, 1) : target.normalize(),
      this.props.position,
    );

    if (this.state.isMouseOverControl) {
      this.controls.enabled = false;
      this.props.onPositionChangeStarted();
    }

    this.setState({
      ...this.state,
      isControlMoving: this.state.isMouseOverControl,
      isMouseDown: true
    });
  }

  handlePointerUp = (e) => {
    e.preventDefault();

    this.props.setActiveMoverName(this.props.name);

    if(this.isMouseOverControl()) {
      this.sphere.material.color = new THREE.Color(MovableTree.sphereColorHover);
    } else {
      this.sphere.material.color = new THREE.Color(MovableTree.sphereColor);
    }
    this.repaint();

    if (this.state.isControlMoving) {
      this.controls.enabled = true;
      this.props.onPositionChangeDone(this._getControlCoordinates());
    }

    this.setState({
      ...this.state,
      isControlMoving: false,
      isMouseDown: false
    });
  }

  isMouseOverControl = () => {
    this.raycaster.setFromCamera(this.mouse, this.camera);
    return this.raycaster.intersectObject(this.sphere).length > 0;
  }

  handlePointerMove = (e) => {
    const rect = this.container.getBoundingClientRect();
    this.mouse.x =  ((e.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((e.clientY - rect.top) / rect.height) * 2 + 1;

    const isMouseOverControl = this.isMouseOverControl();
    if (isMouseOverControl !== this.state.isMouseOverControl) {
      if(isMouseOverControl || this.state.isControlMoving) {
        this.sphere.material.color = new THREE.Color(MovableTree.sphereColorHover);
      } else {
        this.sphere.material.color = new THREE.Color(MovableTree.sphereColor);
      }
      this.repaint();
      this.setState({ ...this.state, isMouseOverControl });
    }
  
    if (!this.state.isControlMoving) return;

    e.preventDefault();
    e.stopPropagation();

    this.raycaster.setFromCamera(this.mouse, this.camera);
    this.raycaster.ray.intersectPlane(this.plane, this.intersection);

    this.props.onPositionChange(this._getControlCoordinates());
  }

  _getControlCoordinates = () => {
    if (this.intersection.y === 0 || this.intersection.x === 0 || this.intersection.z === 0)
      this.intersection = this.sphere.position?.clone?.()

    return this.props.isUp
      ? [this.intersection.x, this.intersection.y, this.props.position.z]
      : [this.props.position.x, this.props.position.y, this.intersection.z];
  }

  render() {
    let cursor;
    if(this.state.isMouseOverControl && !this.state.isMouseDown) {
      cursor = 'pointer';
    } else if (this.state.isMouseDown) {
      cursor = 'grabbing';
    } else {
      cursor = 'grab';
    }

    return (
      <div
        className='tree-mover'
        id={this.scene && this.scene.uuid}
        ref={e => this.container = e}
        style={{ cursor, position: 'relative' }}
        onPointerDown={this.handlePointerDown}
        onPointerMove={this.handlePointerMove}
        onPointerUp={this.handlePointerUp}
      >
        {this.props.error ? <p style={{ position: 'absolute', zIndex: 100, top: 0, left: 10, color: 'white' }}>No pointcloud is available.</p> : null}
        <canvas
          ref={e => this.canvas = e}
          style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}
        />
      </div>
    );
  }
}

export default MovableTree;
