import { sortBy } from 'lodash';
import {
  BackSide,
  DoubleSide,
  Group,
  MathUtils,
  Mesh,
  MeshBasicMaterial,
  SphereGeometry,
  Texture,
  Vector3
} from 'three';
import { generateUUID } from 'three/src/math/MathUtils';
import { PanoramaImageSphere } from './panorama-image-sphere';

export interface LoadOptions {
  image: PanoramaImageSphere;
}

export class SpherePanoramaObject extends Group {
  public image: PanoramaImageSphere | undefined
  public layerMeta: any;
  public session: string = '';
  public items: Mesh[] = [];

  private abortController: AbortController = new AbortController();

  public async createImageLoader(url: string) {
    const response = await fetch(url, {
      signal: this.abortController.signal
    })

    const blob = await response.blob();

    const img = new Image();
    img.src = URL.createObjectURL(blob)

    const texture = new Texture();
    texture.image = img;
    texture.needsUpdate = true;

    return texture;
  }

  public createCamParams() {
    if (!this.image) return;

    const vectors = this.image.createTileVectors(0.000001);

    // @ts-ignore
    const [a, b, c, d] = vectors;
    const ca = a.clone().sub(c.clone()).normalize();
    const cd = d.clone().sub(c.clone()).normalize();
    const cross = ca.clone().cross(cd.clone());
    const center = a.clone().add(d.clone()).divideScalar(2);

    return { center, cross, a, b, c, d };
  }

  private async downloadLayerMetadata(options: LoadOptions) {
    try {
      const jsonUrl = options.image.url
        .replace('panoramas', 'panorama-tiles')
        .replace(/\.(jpeg|jpg)/, '/tiles.json')

      const response = await fetch(jsonUrl, {
        signal: this.abortController.signal
      })

      if (!response.ok) {
        return null
      }

      return await response.json();
    } catch (err) {
      return null
    }
  }

  public async load(options: LoadOptions) {
    this.abortController = new AbortController();

    this.session = generateUUID();
    const currentSession = this.session;

    this.image = options.image;
    this.layerMeta = await this.downloadLayerMetadata(options);

    const pc = this.createCamParams();

    if (!pc) return;

    this.scale.x = -1;
    const centerOfCamZero = pc.cross.clone();

    const normalizedDirection = options.image.directionVector.clone().normalize();
    const normalizedUp = options.image.upVector.clone().normalize();
    this.up = normalizedDirection.cross(normalizedUp);
    this.lookAt(centerOfCamZero.clone());

    /*for MLS images*/
    if(this.up.dot(new Vector3(0,0,1)) < 0){
      this.rotateZ(MathUtils.degToRad(180));
    }

    try {
      this.layerMeta
        ? await this.loadLayers(currentSession)
        : await this.loadFallback(currentSession)
    }
    catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') return;

      console.error(error);
    }
  }

  public async loadLayers(currentSession: string) {
    if (currentSession !== this.session) return;

    const oL = sortBy(this.layerMeta.layers, (item) => -item.layer)

    for (const layer of oL) {
      const deltaRotationX = ((Math.PI * 2) / layer.nx);
      const deltaRotationY = ((Math.PI) / layer.ny);
      
      await Promise.all(layer.items.map(async (item: any) => {
        const baseUrl = this.image?.url.match(/^(.+)\/panorama/)?.[1];
        const imageId = this.image?.url.match(/panoramas\/(.+?)\.jpg|jpeg/)?.[1];
        const queryParams = this.image?.url.match(/(\?.*$)/)?.[1];
        if (!baseUrl || !imageId || !queryParams) return;

        const url = `${baseUrl}/panorama-tiles/${imageId}/${item.path}${queryParams}`
        const texture = await this.createImageLoader(url);

        const geometry = new SphereGeometry(
          100 + layer.layer,
          100,// layer.nx * 100,
          100,// layer.ny * 100,
          Math.PI * (3 / 2) + (deltaRotationX * item.x),
          deltaRotationX,
          (deltaRotationY * item.y),
          deltaRotationY,
        );

        const material = new MeshBasicMaterial({
          map: texture,
          side: BackSide,
          depthTest: false,
          depthWrite: false,
          visible: false
        });

        // This is necessary to prevent blinking (layer fight)
        requestAnimationFrame(() => {
          material.visible = true;
        })

        const mesh = new Mesh(
          geometry,
          material
        )

        this.add(mesh)
      }))
    }
  }

  public async loadFallback(currentSession: string) {
    const texture = await this.createImageLoader(this.image?.url as string);

    if (currentSession !== this.session) return;

    const geometry = new SphereGeometry(10, 1000, 1000, Math.PI * (3 / 2));

    const material = new MeshBasicMaterial({
      map: texture,
      side: DoubleSide,
      depthTest: false,
      depthWrite: false,
    });

    const mesh = new Mesh(
      geometry,
      material
    );

    this.add(mesh)
  }

  public unload() {
    this.abortController.abort();
    this.remove(...this.children)
  }
}
