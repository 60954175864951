import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';
import { getProjectYear } from '../../core/projectSelector/projectSelector';
import useApi from '../api';
import { ObjectValues, StateSetter } from '../../@types/defaults';
import * as _ from 'lodash';
import QCStatuses from '../../@types/enums/QualityCheckStatus';

export const QUALITY_CHECK_FILTERS = {
  QC_ISSUES: 'qc_issues',
  QC_STATUSES: 'qc_statuses',
} as const;

export type QualityCheckFilters = ObjectValues<typeof QUALITY_CHECK_FILTERS>;

export interface BetaManagedAreaContextValue {
  loadingTrees: boolean;
  loadingManagedAreaAndPipeline: boolean;
  loading: boolean;

  errorTrees: any | null;
  errorManagedAreaAndPipeline: any | null;
  error: any | null;

  trees: any[];
  filteredTrees: any[];
  managedArea: any | null;
  pipeline: any | null;
  qualityCheckFilters: Record<QualityCheckFilters, (string | QCStatuses | 'all' | 'reset')[]>;
  qualityCheckIssues: string[];

  fetchTrees: (isQualityCheck?: boolean) => Promise<any[]>;
  fetchManagedAreaAndPipeline: () => Promise<{ managedArea?: any; pipeline?: any } | undefined>;
  fetch: (
    isQualityCheck?: boolean
  ) => Promise<{ trees: any[]; managedArea?: any; pipeline?: any } | undefined>;
  reset: () => Promise<void>;

  startPostValidation: ({ code, id }: { code: any; id: any }, step: string) => Promise<void>;
  moveTreesFromSemanticToMicroclimate: (managedAreaCode: string) => Promise<any>;
  updateQualityCheckFilters: (filters: Record<QualityCheckFilters, (string | QCStatuses | 'all' | 'reset')[]>) => void;
  setQualityCheckIssues: StateSetter<string[]>;
}

export interface BetaManagedAreaContextProviderProps extends PropsWithChildren {
  managedAreaId: number;
  isMicroclimate?: boolean;
}

export const BetaManagedAreaContext = createContext<BetaManagedAreaContextValue>(null as any);

export const useBetaManagedAreaContext = () => useContext(BetaManagedAreaContext);

export const BetaManagedAreaContextProvider = ({
  children,
  managedAreaId,
  isMicroclimate = false,
}: BetaManagedAreaContextProviderProps) => {
  const { handleRequest } = useApi();

  const [loadingTrees, setLoadingTrees] = useState<BetaManagedAreaContextValue['loadingTrees']>(false);
  const [loadingManagedAreaAndPipeline, setLoadingManagedAreaAndPipeline] =
    useState<BetaManagedAreaContextValue['loadingManagedAreaAndPipeline']>(false);
  const loading = useMemo<BetaManagedAreaContextValue['loading']>(
    () => loadingTrees || loadingManagedAreaAndPipeline,
    [loadingTrees, loadingManagedAreaAndPipeline]
  );

  const [errorTrees, setErrorTrees] = useState<BetaManagedAreaContextValue['errorTrees']>(false);
  const [errorManagedAreaAndPipeline, setErrorManagedAreaAndPipeline] =
    useState<BetaManagedAreaContextValue['errorManagedAreaAndPipeline']>(false);
  const error = useMemo<BetaManagedAreaContextValue['error']>(
    () => errorTrees || errorManagedAreaAndPipeline,
    [errorTrees, errorManagedAreaAndPipeline]
  );

  const [trees, setTrees] = useState<BetaManagedAreaContextValue['trees']>([]);
  const [managedArea, setManagedArea] = useState<BetaManagedAreaContextValue['managedArea']>(null);
  const [pipeline, setPipeline] = useState<BetaManagedAreaContextValue['pipeline']>(null);

  const [qualityCheckFilters, setQualityCheckFilters] = useState<BetaManagedAreaContextValue['qualityCheckFilters']>({
    [QUALITY_CHECK_FILTERS.QC_ISSUES]: [],
    [QUALITY_CHECK_FILTERS.QC_STATUSES]: [],
  });

  const [qualityCheckIssues, setQualityCheckIssues] = useState<BetaManagedAreaContextValue['qualityCheckIssues']>([]);

  const scanYear = getProjectYear();
  const queryYear = !!scanYear ? `scanYear=${scanYear}` : 'latest=1';

  const updateQualityCheckFilters = useCallback(
    async (filters: Record<QualityCheckFilters, (string | QCStatuses | 'all' | 'reset')[]>) => {
      setQualityCheckFilters((prev) => ({
        ...prev,
        ...filters,
      }));
    },
    [setQualityCheckFilters]
  );

  const reset = useCallback(async () => {
    setLoadingTrees(false);
    setLoadingManagedAreaAndPipeline(false);
    setErrorTrees(null);
    setErrorManagedAreaAndPipeline(null);
    setTrees([]);
    setManagedArea(null);
    setPipeline(null);
    setQualityCheckFilters({
      [QUALITY_CHECK_FILTERS.QC_ISSUES]: [],
      [QUALITY_CHECK_FILTERS.QC_STATUSES]: [],
    });
  }, []);

  const fetchTrees = useCallback(
    async (isQualityCheck?: boolean) => {
      if (!managedAreaId) {
        console.warn('BetaManagedAreaContextProvider has not ManagedAreaId defined');
        return [];
      }

      try {
        setLoadingTrees(true);
        setErrorTrees(null);

        const qualityCheckString = isQualityCheck ? `&isQualityCheck=true` : '';

        const treesResponse = await handleRequest(
          `v1/trees?mas=${managedAreaId}&pi=true&${queryYear}&q=${new Date().getTime()}${
            isMicroclimate ? `&vs=microclimate` : ''
          }${qualityCheckString}`
        ).then((response) => response.json());

        if (!Array.isArray(treesResponse)) {
          throw new Error('TreesNotFound');
        }

        setTrees(treesResponse);

        return treesResponse;
      } catch (e) {
        console.error(e, managedAreaId, queryYear, isMicroclimate, isQualityCheck);
        setErrorTrees(e as any);
      } finally {
        setLoadingTrees(false);
      }
      return [];
    },
    [handleRequest, isMicroclimate, queryYear, managedAreaId]
  );

  const fetchManagedAreaAndPipeline = useCallback(async () => {
    if (!managedAreaId) {
      console.warn('BetaManagedAreaContextProvider has not ManagedAreaId defined');
      return;
    }

    try {
      setLoadingManagedAreaAndPipeline(true);
      setErrorManagedAreaAndPipeline(null);

      const managedAreaResponse = await handleRequest(`/v1/query/managed_areas?eq_column=id&eq_value=${managedAreaId}&${queryYear}`)
        .then((response) => response.json());

      if (!managedAreaResponse?.[0]) {
        throw new Error('ManagedAreaNotFound');
      }

      const pipelineResponse = await handleRequest(
        `/v1/pipelines/get?managedAreaCode=${managedAreaResponse[0].code}&${queryYear}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
        }
      ).then((response) => response.json());

      if (!pipelineResponse?.[0]) {
        throw new Error('PipelineNotFound');
      }

      setManagedArea(managedAreaResponse[0]);
      setPipeline(pipelineResponse[0]);

      return {
        managedArea: managedAreaResponse[0],
        pipeline: pipelineResponse[0],
      };
    } catch (e) {
      console.error(e, managedAreaId, queryYear);
      setErrorManagedAreaAndPipeline(e as any);
    } finally {
      setLoadingManagedAreaAndPipeline(false);
    }
  }, [handleRequest, managedAreaId, queryYear]);

  const fetch = async (isQualityCheck?: boolean) => {
    const [trees, combined] = await Promise.all([fetchTrees(isQualityCheck), fetchManagedAreaAndPipeline()]);

    combined?.managedArea?.id && localStorage.setItem('managedAreaId', combined?.managedArea?.id);

    return {
      trees,
      managedArea: combined?.managedArea,
      pipeline: combined?.pipeline,
    };
  };

  const startPostValidation = useCallback(
    async ({ code, id }: { code: any; id: any }, step: any) => {

    },
    []
  );

  const moveTreesFromSemanticToMicroclimate = useCallback(
    async (managedAreaCode: string) => {
      await handleRequest(`/v1/trees/semantic-to-ecosystem`, {
        method: 'PATCH',
        body: JSON.stringify({
          managedAreaCode,
        }),
        headers: { 'Content-Type': 'application/json' },
      });
    },
    [handleRequest]
  );

  const filteredTrees: BetaManagedAreaContextValue['filteredTrees'] = useMemo(() => {
    let fTrees: BetaManagedAreaContextValue['filteredTrees'] = _.clone(trees);

    if (!trees?.length) return [];
    if (!qualityCheckFilters || 
      (!qualityCheckFilters.qc_issues?.length && !qualityCheckFilters.qc_statuses?.length)) {
      return trees;
    }

    if(qualityCheckFilters.qc_statuses?.length) {
      fTrees = _.clone(fTrees).filter((tree) => qualityCheckFilters.qc_statuses.includes(tree.qc_status));
    }

    if(qualityCheckFilters.qc_issues?.length) {
      fTrees = _.clone(fTrees).filter((tree) => tree?.qc_issues?.find((issue: string) => qualityCheckFilters?.qc_issues?.includes(issue as string)));
    }

    return fTrees;
  }, [trees, qualityCheckFilters]);

  const value: BetaManagedAreaContextValue = {
    loadingTrees,
    loadingManagedAreaAndPipeline,
    loading,

    errorTrees,
    errorManagedAreaAndPipeline,
    error,

    trees,
    filteredTrees,
    managedArea,
    pipeline,
    qualityCheckFilters,
    qualityCheckIssues,

    fetchTrees,
    fetchManagedAreaAndPipeline,
    fetch,

    reset,
    startPostValidation,
    moveTreesFromSemanticToMicroclimate,
    updateQualityCheckFilters,
    setQualityCheckIssues,
  };

  return <BetaManagedAreaContext.Provider value={value}>{children}</BetaManagedAreaContext.Provider>;
};
