import React, { cloneElement, Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Grid } from '.';
import { Controls, Map, MapControls, Panorama, Table } from '../components';
import { Button } from '../components/inputs';
import Proximity from '../components/modals/Proximity';
import useApi from '../hooks/api';
import { usePanoramaChangeOnMap } from '../hooks/panoramicViewToMap';
import { useConfig } from '../providers/config';
import { useModal } from '../providers/modal';
import { useTheme } from '../providers/theme';
import { useBetaTree } from '../hooks/betaHooks/beta-use-tree';
import TreeFlowStatus from '../@types/enums/TreeFlowStatus';
import { useBetaManagedAreaContext } from '../hooks/betaHooks/beta-managed-area-context';

const generateTableConfig = (
  selection,
  selectedTree,
  customColumns,
  proximityDataVisible,
  proximityAlertTreeIDs
) => ({
  columns:
    customColumns?.(selection, proximityDataVisible, proximityAlertTreeIDs) ??
    (selection
      ? [
          {
            title: 'RTMS ID',
            key: 'id',
            resolver: 'id',
            sortable: true,
          },
          {
            title: 'Managed area',
            key: 'MA',
            resolver: 'managed_area',
            format: 'CODE',
            sortable: true,
          },
          {
            title: 'Confidence level',
            key: 'confidence',
            resolver: ['location_confidence', 'ml_instance_confidence', 'ml_semseg_confidence', 'confidence_girth_ellipse'],
            format: 'PERCENTAGE',
            sortable: true,
          },
          {
            title: 'Status',
            resolver: 'status',
            key: 'status',
            format: 'STATUS',
            sortable: true,
          },
          {
            title: 'Comment',
            key: 'comment',
            resolver: ({ comment }) => (comment ? comment.map(({ value }) => value).join(', ') : '-'),
            sortable: true,
          },
        ]
      : [
          {
            title: 'Area code',
            key: 'code',
            resolver: 'code',
            format: 'CODE',
            sortable: true,
          },
          {
            title: 'Status',
            key: 'status',
            resolver: (ma) => {
              if (ma.location_proposal === 'error' || ma.semantic_extraction === 'error' || ma.segmentation === 'error') return 'ERROR';
              if (
                ma.location_proposal === 'todo' ||
                ma.semantic_extraction === 'todo' ||
                ma.segmentation === 'todo' ||
                ma.location_proposal === 'started' ||
                ma.semantic_extraction === 'started' ||
                ma.segmentation === 'started' ||
                ma.processing
              )
                return 'PROCESSING';
              return ma.current_manual_step || 'completed';
            },
            format: 'STATUS',
            sortable: true,
          },
          {
            title: 'OIC',
            key: 'oic',
            resolver: (ma) => (ma.oic_full_name ? ma.oic_full_name : ma.oic_user_name) || '-',
            sortable: true,
          },
        {
          title: 'Tree number',
          key: 'tree_number',
          resolver: 'tree_number',
          sortable: true,
        },
        {
          title: 'Pending',
          key: 'pending',
          resolver: 'pending',
          sortable: true,
        },
        {
          title: 'Validated',
          key: 'validated',
          resolver: 'validated',
          sortable: true,
        },
        {
          title: 'Accepted',
          key: 'accepted',
          resolver: 'accepted',
          sortable: true,
        },
        {
          title: 'Sent to Field',
          key: 'sent_to_field',
          resolver: 'sent_to_field',
          sortable: true,
        },
        {
          title: 'Deleted',
          key: 'deleted',
          resolver: 'deleted',
          sortable: true,
        },
        {
          title: 'Manually corrected',
          key: 'manually_corrected',
          resolver: 'manually_corrected',
          sortable: true,
        },
        ]),
  selection: (entry) => {
    return selectedTree && entry && parseInt(selectedTree.id) === parseInt(entry.id);
  },
});

const MASelectorMap = ({
  basePath,
  mapRef,
  selection,
  customColumns,
  resetFocus,
  focusOnMA,
  selectedTree,
  setSelectedTree,
  layerSources,
  sliders,
  onShouldReload,
  startAction,
  controls,
  tableHeader,
  needReload,
  setNeedReload,
  proximityAlertTreeIDs,
  setProximityAlertTreeIDs,
  fullHeight,
  screenType,
  trees,
  todoTrees,
  currentManagedArea,
  pipeline,
  pipelines,
  contextsLoadingState,
  managedArea,
  tableSortOptions,
  uploadQCTreesModalOpen,
  setUploadQCTreesModalOpen,
  refetchQualityCheckTrees,
  handleTableSortChange,
  handleTableConfigChange,
  setMaFilter,
  mapLayers,
  filterLayers,
  sourceVisible,
  setSourceVisible,
  layerVisible,
  setLayerVisible,
  isQualityCheck,
}) => {
  const history = useHistory();
  const location = useLocation();
  const betaMACtx = useBetaManagedAreaContext();

  const [tableFiltersIsOpen, setTableFiltersIsOpen] = useState(false);
  const { isDark } = useTheme();
  const { handleRequest } = useApi();

  useEffect(() => {
    if (needReload) setNeedReload(false);
  }, [needReload, setNeedReload]);

  const _handleTreeSelectForPanorama = (tree) => {
    if (!tree) return;
    setSelectedTree(tree);
  };


  const { presentModal, dismissModal } = useModal();
  const [proximityDataVisible, setProximityDataVisible] = useState(false);
  const proximityCheckAvailable = todoTrees?.length > 0 && pipeline?.current_manual_step === 'location_validation';

  const forwardedTableConfig = useMemo(() => {
    return generateTableConfig(
      selection[1],
      selectedTree,
      customColumns,
      proximityDataVisible,
      proximityAlertTreeIDs
    );
  }, [
    selectedTree,
    selection,
    customColumns,
    proximityDataVisible,
    proximityAlertTreeIDs,
  ]);

  useEffect(() => {
    handleTableConfigChange(forwardedTableConfig);
  }, [handleTableConfigChange, forwardedTableConfig]);

  const onCheckProximity = useCallback(
    async (distance) => {
      const res = await handleRequest(`/v1/trees-proximity?ma=${pipeline.id}&distance=${distance}`);
      const data = await res.json();

    dismissModal();
    setProximityAlertTreeIDs(data);
    setProximityDataVisible(true);
    handleTableSortChange({ key: 'proximity', direction: -1 });
  }, [
    dismissModal, 
    handleRequest, 
    pipeline?.id, 
    setProximityAlertTreeIDs, 
    handleTableSortChange
  ]);

  const proximityHandler = useCallback(() => {
    presentModal({
      title: 'Search for trees that have another tree in the given distance',
      content: <Proximity onCancel={dismissModal} getProximity={onCheckProximity} />,
    });
  }, [dismissModal, onCheckProximity, presentModal]);

  const { getConfig } = useConfig();
  const [isSatellite, setIsSatellite] = useState(false);

  const _handleStart = () => {
    history.push(`${location.pathname}/${startAction.step}/${startAction.treeId}`.replace('//', '/'));
  };

  const _handleUnselect = () => history.push(`/${basePath}`);

  // Panorama - for any map position
  const [isPanoramaSelected, setPanoramaSelected] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState(null);

  const isPanoramaVisible = isPanoramaSelected && (selectedTree || selectedPoint);

  const _handleOnMapClick = useCallback(
    (e) => {
      if (isPanoramaSelected && !selectedTree && !selectedPoint) {
        setSelectedPoint([e.lngLat.lng, e.lngLat.lat]);
      }
    },
    [isPanoramaSelected, selectedPoint, selectedTree]
  );

  useEffect(() => {
    if (!isPanoramaSelected) {
      setSelectedPoint(null);
      setSelectedTree(null);
    }
  }, [setSelectedTree, isPanoramaSelected]);

  useEffect(() => {
    if (selectedTree) {
      setSelectedPoint(null);
    }
  }, [selectedTree]);

  const mapControls = [
    {
      id: 'map-controller',
      label: 'Basic',
      component: (
        <MapControls
          layers={filterLayers.filter((layer) => !(layer.noFilter === true))}
          layersVisible={layerVisible}
          onLayerVisibleChange={setLayerVisible}
          showLayerFilters
          sources={layerSources}
          sourcesVisible={sourceVisible}
          onSourceVisibleChange={setSourceVisible}
          showSourceFilters
          showViewOptions
          isSatellite={isSatellite}
          setIsSatellite={setIsSatellite}
          isPanoramaVisible={isPanoramaVisible}
          isPanoramaSelected={isPanoramaSelected}
          setPanoramaSelected={setPanoramaSelected}
        />
      ),
    },
  ];

  useEffect(() => {
    focusOnMA?.();
  }, [focusOnMA]);

  const { cameraPosition, handlePanoramicViewChange } = usePanoramaChangeOnMap(
    mapRef
  );

  const onClickForTable = useMemo(() => {
    const _handleEntryClick = (entry, index) => {
      !selection[1] && history.push(`/${basePath}/${entry.id}`);
    };

    const _handleTreeRowClick = (tree, index) => {
      setSelectedTree(tree);

      const centre = {
        lat: JSON.parse(tree?.location)?.coordinates?.[1],
        lng: JSON.parse(tree?.location)?.coordinates?.[0],
      };
      const feature = { id: tree.id, properties: tree };
      mapRef.current?.showPopup(tree.tree_flow_status, centre, feature);
      mapRef.current?.focusOnPoint(JSON.parse(tree?.location)?.coordinates || []);
    };

    return selection[1] ? _handleTreeRowClick : _handleEntryClick;
  }, [setSelectedTree, basePath, history, mapRef, selection]);

  const tableData = useMemo(() => (selection[1] ? trees : pipelines) || [], [selection, pipelines, trees]);

  const betaTreeHook = useBetaTree({
    ignoreAutoPull: true,
    isQualityCheck: isQualityCheck,
  });

  return (
    <Grid
      fullHeight={fullHeight}
      defaultLayout={{ x: 0.0, y: 0.0 }}
      tabcontent={
        <Controls
          tabs={[...(controls || []), ...(mapControls || [])].map((child) => {
            if (React.isValidElement(child.component))
              return {
                ...child,
                component: cloneElement(child.component, { map: mapRef, mapRef, setMaFilter }),
              };
            return child;
          })}
        />
      }
      infocontent={
        <Fragment>
          {tableHeader}
          <Table
            key='MAP'
            data={tableData}
            config={forwardedTableConfig}
            onClick={onClickForTable}
            preventAutoFocus
            updateTree={async (id, data) => {
              await betaTreeHook.updateTreeWithId(id, data);
              await betaMACtx.fetchTrees();
            }}
            contextsLoadingState={contextsLoadingState}
            pipeline={pipeline}
            tableSortOptions={tableSortOptions}
            setQualityCheckStatus={async (id, qc_status) => {
              await betaTreeHook.setQualityCheckStatus(id, qc_status);
              await refetchQualityCheckTrees(managedArea.id);
            }}
            sendToOfflineAnnotation={async (id) => {
              await betaTreeHook.updateTreeWithId(id, {
                status: 'sent_to_offline_annotation',
                tree_flow_status: TreeFlowStatus.SentToOfflineAnnotation,
              });
              await refetchQualityCheckTrees(managedArea.id);
            }}
            onTableSortChange={handleTableSortChange}
          />
        </Fragment>
      }
    >
      <div className='validation-overview-wrapper'>
        {isPanoramaVisible && (
          <div className='panorama-map-container'>
            <Panorama
              position={JSON.parse(selectedTree?.location)?.coordinates?.slice(0, 2) || selectedPoint}
              background={isDark ? 0x000000 : 0xf8f8f8}
              onTreeClick={_handleTreeSelectForPanorama}
              getConfig={getConfig}
              onViewChange={handlePanoramicViewChange}
              selTree={selectedTree}
              selectedMA={currentManagedArea}
              mapSources={sourceVisible}
              mapLayers={mapLayers}
            />
          </div>
        )}
        <Map
          needReload={needReload}
          minimap={isPanoramaVisible}
          key='validationMap'
          _ref={mapRef}
          sources={layerSources}
          sourceVisible={sourceVisible}
          layers={mapLayers}
          onUnselect={selection[1] && _handleUnselect}
          onPureClick={_handleOnMapClick}
          layerVisible={layerVisible}
          cameraPosition={isPanoramaVisible && cameraPosition}
          active={{
            mas: currentManagedArea,
            ...filterLayers.reduce((prev, { id }) => ({ ...prev, [id]: selectedTree?.id }), {}),
          }}
          satellite={isSatellite}
          onFocusReset={resetFocus}
          sliders={sliders}
          action={<>
            {proximityCheckAvailable && <Button label="Proximity check" primary={true} onClick={proximityHandler} />}
            {startAction.isAvailable && <Button label="Start" primary={true} onClick={_handleStart}/>}
          </>}
          onShouldReload={onShouldReload}
          crosshair={isPanoramaSelected && !isPanoramaVisible}
          tableFiltersIsOpen={tableFiltersIsOpen}
          setTableFiltersIsOpen={setTableFiltersIsOpen}
          screenType={screenType}
          managedAreaId={managedArea?.id}
          uploadQCTreesModalOpen={uploadQCTreesModalOpen}
          setUploadQCTreesModalOpen={setUploadQCTreesModalOpen}
        />
      </div>
    </Grid>
  );
};

export default memo(MASelectorMap);
