import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { Panorama, TreeMover } from '../../components';
import { Button } from '../../components/inputs';
import { useTheme } from '../../providers/theme';
import useApi from '../../hooks/api';
import * as THREE from 'three';
import LASLoader from '../../utils/LASLoader';
import ProgressLoader from '../../components/ProgressLoader';
import { useBetaTree } from '../../hooks/betaHooks/beta-use-tree';
import { useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import { Vector3 } from 'three';
import { generateUrl } from '../../helpers/ValidationUtils';
import proj4 from 'proj4';

const loadingManager = new THREE.LoadingManager();

const TreeAdd = ({ match, loaded, selection }) => {
  const history = useHistory();
  const { isDark } = useTheme();
  const queryParameters = new URLSearchParams(window.location.search);
  const offset = queryParameters.get('offset').split(',').map(Number);

  const [virtualTreePosition, setVirtualTreePosition] = useState(offset);

  const betaManagedAreaContext = useBetaManagedAreaContext();

  const betaTree = useBetaTree({
    managedAreaCode: betaManagedAreaContext?.managedArea?.code,
    managedAreaId: betaManagedAreaContext?.managedArea?.id,
    ignoreAutoPull: true,
  });

  const currentTree = betaTree?.tree;

  const _handleRemove = async () => {
    history.goBack();
  };

  const handleAcceptTreeProposal = useCallback(async () => {
    const coordinatesToSave = proj4('localProjection', 'EPSG:4326', virtualTreePosition || []);
    const newTree = await betaTree.addTree(
      coordinatesToSave[0],
      coordinatesToSave[1],
      betaManagedAreaContext.managedArea?.code,
      betaManagedAreaContext.managedArea?.id,
      betaManagedAreaContext.pipeline?.pipeline_id
    );

    history.push(generateUrl(newTree?.id));
  }, [
    virtualTreePosition,
    betaTree,
    betaManagedAreaContext.managedArea?.code,
    betaManagedAreaContext.managedArea?.id,
    betaManagedAreaContext.pipeline?.pipeline_id,
    history,
  ]);

  const [lasError, setLasError] = useState(null);
  const { handleRequest } = useApi();
  const [availablePointClouds, updateAvailablePointClouds] = useState([]);
  const [activePcIndex, updateActivePcIndex] = useState(0);
  const [loadingState, setLoadingState] = useState([0, 100]);

  const _handleLasLoad = useCallback((path, pcIndex) => {
    setLasError(null);
    setLoadingState([0, 100]);

    const loader = new LASLoader(loadingManager);

    loader.load(
      path,
      (pointcloud) => {
        updateAvailablePointClouds((pcs) => {
          const newPcs = [...pcs];
          newPcs[pcIndex].pointcloud = pointcloud;

          return newPcs;
        });

        setLasError(null);
      },
      () => void 0,
      (e) => {
        setLoadingState([e.loaded, e.total]);
        updateAvailablePointClouds((old) => [...old.slice(0, pcIndex), ...old.slice(pcIndex + 1)]);

        setLasError(true);
      }
    );
  }, []);

  const getLas = useCallback(async () => {
    const response = await handleRequest('/v1/location/las_tiles/' + offset[0] + ',' + offset[1] + ',4326');
    const responseJson = await response.json();

    await updateAvailablePointClouds(
      responseJson?.map((res) => ({
        path: res.laz_path,
        laz_path_url: res.laz_path_url,
        pointcloud: null,
      }))
    );

    const lazUrl = responseJson[0].laz_path_url;

    _handleLasLoad(lazUrl, 0);
  }, [_handleLasLoad, handleRequest, offset]);

  useEffect(() => {
    if (!offset.length) return;

    getLas();
  }, []);

  const _switchActivePointCloud = useCallback(
    ({ offset }) => {
      if (availablePointClouds.length > 0) {
        const newIndex = (activePcIndex + offset + availablePointClouds.length) % availablePointClouds.length;
        updateActivePcIndex(newIndex);

        if (!availablePointClouds[newIndex].pointcloud) {
          _handleLasLoad(availablePointClouds[newIndex].laz_path_url, newIndex);
        } else if (offset === 0) {
          setLasError(null);
        }
      }
    },
    [_handleLasLoad, activePcIndex, availablePointClouds]
  );

  useEffect(() => {
    if (lasError !== null) _switchActivePointCloud({ offset: 0 });
  }, [_switchActivePointCloud, lasError]);

  return (
    <div className='location-validation-wrapper'>
      <div className='viewers'>
        <div className='panorama-view-wrapper'>
          <Panorama
            treeId={currentTree?.id}
            managedAreaCode={selection[0]?.code}
            virtualTreePosition={new Vector3().fromArray(virtualTreePosition)}
            enabledMiniMap={false}
          />
        </div>
        <div className='vertical-stack'>
          <TreeMover
            treeLoaded={loaded}
            pointcloud={availablePointClouds[activePcIndex]?.pointcloud || null}
            error={lasError}
            background={isDark ? 0x000000 : 0xf8f8f8}
            position={virtualTreePosition}
            onPositionChange={setVirtualTreePosition}
            loaderBtn={
              availablePointClouds.length > 1 ? (
                <div className='next-pointcloud-btn'>
                  <Button onClick={() => _switchActivePointCloud({ offset: 1 })} label='View another pointcloud' />
                </div>
              ) : null
            }
            loader={
              <ProgressLoader
                label={loadingState[0] / loadingState[1] > 0.95 ? 'Processing' : 'Downloading'}
                currentCount={loadingState[0]}
                targetCount={loadingState[1]}
              />
            }
          />
        </div>
      </div>
      <div className='location-actions-wrapper'>
        <div className='action-wrapper'>
          <Button label='Cancel' onClick={_handleRemove} />
        </div>
        <div className='action-wrapper'>
          <Button label='Save' primary onClick={handleAcceptTreeProposal} />
        </div>
      </div>
    </div>
  );
};

export default TreeAdd;
