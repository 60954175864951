import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import useApi from "../hooks/api";
import { useAuth } from "./auth-provider";
import { User } from "../@types/User";

interface UserContextValue {
  initialized: boolean;
  name: string;
  hasRole: (role: string) => boolean;
  hasAnyRole: (roles: string[]) => boolean;
}

interface UserProviderProps extends PropsWithChildren {};

const UNKNOWN_USERNAME = 'NA'

const UserContext = createContext<UserContextValue | null>(null);

export const UserProvider = ({ children } : UserProviderProps) => {
  const { authenticated, account } = useAuth();
  const { handleRequest } = useApi();
  const [user, setUser] = useState<User | null>(null);

  const loadUserProfile = useCallback(async () => {
    const response = await handleRequest(`/v1/user`).then((res) => res.json());
    setUser({
      name: account.name || UNKNOWN_USERNAME,
      roles: response.roles,
      lastLogin: response.lastLogin
    });
  }, [handleRequest, account]);

  useEffect(() => {
    if (!authenticated) return;

    loadUserProfile();
  }, [authenticated, loadUserProfile]);

  const hasRole = (role: string) => {
    if (!user) return false;

    return user.roles?.includes(role) ?? false;
  };

  const hasAnyRole = (roles: string[]) => {
    if (!user) return false;
    const rolesIntersection = user.roles?.filter(r => roles.includes(r)) ?? [];
    return rolesIntersection.length > 0;
  };

  const value: UserContextValue = {
    initialized: !!user,
    name: user?.name || UNKNOWN_USERNAME,
    hasRole: hasRole,
    hasAnyRole: hasAnyRole
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error('No UserProvider found when calling useUser()');
  }

  return userContext;
};
