import React from 'react';
import { createRoot } from 'react-dom/client'
import './styles/main.scss';
import Navigation from './routes';
import Providers from './providers';
import './core/library';

// Prototypes    
String.prototype.toPath = function() {
  return this.split(/\[([^}]+)\]|\./g).filter(segment => !!segment);
}

Object.byPath = function(object, path) {
  path = Array.isArray(path) ? path : String(path).toPath();
  return path.reduce((prev, key) => (prev && typeof prev[key] !== undefined) ? prev[key] : null, object);
};

Object.byResolver = function(object, resolver) {
  return typeof(resolver) === 'function' ? resolver(object) : Object.byPath(object, resolver);
};

Object.toKebab = function(object) {
  return Object.keys(object).reduce((prev, key) => ({ ...prev, [key.split(/(?=[A-Z])/).join('-').toLowerCase()]: object[key]}), {})
};

Array.groupByTwo = function(array) {
  return array.reduce((acc, val, ind, array) => {
    const { data, prev } = acc;
    if (prev) data.push([prev, val])

    return { data, prev: prev ? null : val }
  }, { data: [], prev: null }).data;
};

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Providers>
      <Navigation />
    </Providers>
  </React.StrictMode>
);
