import { useEffect, useState } from 'react';
import proj4 from 'proj4';
import * as epsg from 'epsg';
import useApi from './api';
import { useAuth } from '../providers/auth-provider';

const useProj4Defs = () => {
  const [loaded, setLoaded] = useState(false);
  const { handleRequest } = useApi();
  const { 
    initialized: authInitialized,
    authenticated
   } = useAuth();

  useEffect(() => {
    if (authInitialized && authenticated) {
      const load = async () => {
        const res = await handleRequest('/v1/project-config');
        const data = await res.json();

        const srid = data.srid;
        proj4.defs('localProjection', epsg[`EPSG:${srid}`]);
        proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');

        setLoaded(true);
      };

      load();
    }
  }, [handleRequest, authInitialized, authenticated]);

  return { loaded };
};

export default useProj4Defs;
