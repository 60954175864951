import { useState, useEffect, useMemo, useCallback } from 'react';
import { Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Skeleton, Route } from '.';
import ManagedAreaMap from './MASelectorMap';
import { useBetaManagedAreaContext } from '../hooks/betaHooks/beta-managed-area-context';
import { useBetaManagedAreasContext } from '../hooks/betaHooks/beta-managed-areas-context';
import getHeaderTitle from '../utils/getHeaderTitle';
import useBetaTableSorter from '../hooks/betaHooks/beta-use-table-sorter';
import { useBetaTreeIndex } from '../hooks/betaHooks/beta-use-tree-index';
import { useConfig } from '../providers/config';
import TreePopup from '../components/TreePopup';
import { QC_STATUSES } from '../@types/enums/QualityCheckStatus';

const SkeletonWithManagedAreaMap = ({
  basePath,
  screens = [],
  title,
  mapRef,
  layerFilter,
  layerSources = [
    { id: 'trees', source: 'trees', name: 'Trees' },
    { id: 'mas', source: 'managed_areas', name: 'Managed Areas' },
  ],
  sliders,
  treePopup,
  treesFilter,
  controls = [],
  tableHeader,
  customColumns = null,
  treeStatusFilter,
  maStatusFilter,
  screenType,
  selection,
  contextsLoadingState,
  activeTabs,
  setActiveTabs,
  setOfficerInCharge,
  isMicroClimate,
  isQualityCheck,
  uploadQCTreesModalOpen,
  setUploadQCTreesModalOpen,
  refetchQualityCheckTrees,
  todoTreesFilter,
  generateNavigationTabs,
}) => {
  const history = useHistory();
  const urlMatch = useRouteMatch('/:viewType/:ma?/:validationType?/:tree?');
  const { getConfig } = useConfig();

  const [maFilter, setMaFilter] = useState(null);

  const betaMASCtx = useBetaManagedAreasContext();
  const betaMACtx = useBetaManagedAreaContext();

  const pipelines = betaMASCtx.filteredPipelines;
  const pipeline = betaMACtx.pipeline;
  const managedAreas = betaMASCtx.filteredManagedAreas;
  const managedArea = betaMACtx.managedArea;
  const trees = isQualityCheck ? betaMACtx.filteredTrees : treesFilter ? betaMACtx.trees.filter(treesFilter) : betaMACtx.trees;

  const { sortedData, tableSortOptions, handleTableSortChange, handleTableConfigChange } = useBetaTableSorter({
    data: managedArea?.id ? trees : pipelines,
  });
  const sortedPipelines = useMemo(() => (!managedArea?.id ? sortedData : []), [managedArea?.id, sortedData]);
  const sortedTrees = useMemo(() => (managedArea?.id ? sortedData : []), [managedArea?.id, sortedData]);

  const [selectedTree, setSelectedTree] = useState(null);
  const selectedTreeId = selectedTree?.id ?? null;
  const currentTreeId = urlMatch.params.tree ?? null;

  useEffect(() => {
    betaMASCtx.filterPipelinesByStatus(treeStatusFilter, maStatusFilter);
  }, [treeStatusFilter, maStatusFilter]);

  const [proximityAlertTreeIDs, setProximityAlertTreeIDs] = useState([]);
  const [isToolbarVisible, setIsToolbarVisible] = useState(true);
  const [deletedTrees, setDeletedTrees] = useState([]);

  const _handleMAFocus = () => {
    const ps = sortedPipelines?.length ? sortedPipelines : pipelines;
    const bbox = ps.map((pipeline) => pipeline?.bbox && JSON.parse(pipeline?.bbox).coordinates);
    if (bbox && bbox.length > 1) {
      mapRef.current.focusOnBBox(bbox);
    }
  };

  const _handleMapFocus = () => {
    if (!mapRef.current) return;
    if (managedArea?.id) mapRef.current.focusOnBBox(managedArea?.frontend_aoi_bbox?.coordinates);
    else _handleMAFocus();
  };

  useEffect(() => {
    _handleMapFocus();
  }, [pipelines?.length, managedArea?.id]);

  const todoTrees = useMemo(() => {
    const treesToFilter = sortedTrees?.length ? sortedTrees : trees;
    return treesToFilter?.filter(todoTreesFilter) ?? [];
  }, [trees, sortedTrees, todoTreesFilter]);

  const { selectTree, moveToNeighbour, getNextTree } = useBetaTreeIndex({
    trees: sortedTrees?.length ? sortedTrees : trees,
    todoTreesFilter,
    currentTreeId,
    skippedTrees: JSON.parse(localStorage.getItem('skippedTrees') || '[]'),
    setTree: (tree) => {
      setSelectedTree(tree);
      const validationType = urlMatch.params.validationType;
      if (basePath === 'validation') {
        history.push(`/validation/${managedArea.id}/${validationType}/${tree.id}`);
      } else if (basePath === 'microclimate-input') {
        history.push(`/microclimate-input/${managedArea.id}/validation/${tree.id}?left=${activeTabs.left}&right=${activeTabs.right}`);
      } else if (isQualityCheck) {
        history.push(`/quality_check/${managedArea.id}/${validationType}/${tree.id}`);
      }
    },
  });

  useEffect(() => {
    const storedManagedAreaId = localStorage.getItem('managedAreaId');
    const currentManagedAreaId = managedArea?.id;
    const skippedTrees = JSON.parse(localStorage.getItem('skippedTrees') || '[]');
    if ((storedManagedAreaId && currentManagedAreaId && 
      storedManagedAreaId.toString() !== currentManagedAreaId.toString()) ||
      (currentManagedAreaId && skippedTrees.length >= todoTrees.length))
    {
      localStorage.removeItem('skippedTrees');
    }
  }, [managedArea?.id, todoTrees.length]);

  const addSkippedTree = useCallback(
    (treeId) => {
      const skippedTrees = JSON.parse(localStorage.getItem('skippedTrees') || '[]');
      if (skippedTrees.indexOf(treeId) === -1) {
        skippedTrees.push(treeId);

        if (skippedTrees.length < todoTrees.length) {
          localStorage.setItem('skippedTrees', JSON.stringify(skippedTrees));
        } else {
          localStorage.removeItem('skippedTrees');
        }
      }
    },
    [todoTrees.length]
  );

  const removeSkippedTree = useCallback((treeId) => {
    const skippedTrees = JSON.parse(localStorage.getItem('skippedTrees') || '[]');
    const index = skippedTrees.indexOf(treeId);
    if (index !== -1) {
      skippedTrees.splice(index, 1);
      localStorage.setItem('skippedTrees', JSON.stringify(skippedTrees));
    }
  }, []);

  const handleChangeTree = (direction) => {
    if (!isMicroClimate) {
      // Currently on the microclimate view all trees are used for navigation, not just
      // the todo trees, so the skipped tree feature will not work properly. You would
      // have to navigate through all trees (even completed ones) to get to the
      // previously  skipped trees again.
      if (direction === 1) {
        addSkippedTree(currentTreeId);
      } else {
        removeSkippedTree(currentTreeId);
      }
    }
    moveToNeighbour(direction);
  };

  const steps = {
    location_validation: 'location',
    semantic_validation: 'semantics',
    segmentation_validation: 'segmentation',
  };

  const startTreeId = useMemo(() => {
    let startTreeId = selectedTreeId;
    if (!startTreeId && todoTrees[0]) {
      const skippedTrees = JSON.parse(localStorage.getItem('skippedTrees') || '[]');
      const relevantTrees = todoTrees.filter((tree) => !skippedTrees.includes(tree.id));
      startTreeId = relevantTrees[0]?.id ?? todoTrees[0].id;
    }
    return startTreeId;
  }, [selectedTreeId, todoTrees]);

  const needStartAction = () => {
    if (!todoTrees?.length) return false;

    if (!isQualityCheck && !isMicroClimate && pipeline) {
      const logicForBaseValidations = Object.keys(steps).includes(pipeline?.current_manual_step);

      return logicForBaseValidations;
    }

    return true;
  };

  const startAction = {
    isAvailable: needStartAction(),
    step: isQualityCheck ? 'semantics' : isMicroClimate ? 'validation' : steps[pipeline?.current_manual_step],
    treeId: startTreeId,
  };

  const maFilterForLayers = [
    'case',
    ['in', ['get', 'code'], ['literal', betaMASCtx.filteredPipelines.map((pipeline) => pipeline.managed_area)]],
    true,
    false,
  ];

  const _handleMASelect = (centerCoord, layer, layerId) => {
    history.push(`/${basePath}/${layer.id}`);
    setSelectedTree(null);
  };

  const _handleTreeSelectForMap = (centerCoord, tree, layerId) => {
    if (!tree) return;
    // Coordinates are needed because the tree object from map doesn't contain it
    tree.location = { coordinates: [centerCoord.lng, centerCoord.lat] };
    setSelectedTree(tree);
  };

  const maLayer = {
    id: 'mas',
    source: 'mas',
    type: 'fill',
    color: '#082',
    onClick: _handleMASelect,
    opacity: 0.8,
    below: layerFilter[0]?.id,
    filter: maFilterForLayers,
    highlight: maFilter,
  };

  const generateFilter = (status, filter = true, statuses) => {
    let treeFilter = [];

    if (isQualityCheck) {
      treeFilter = statuses ? ['in', ['get', 'qc_status'], ['literal', statuses]] : ['==', ['get', 'qc_status'], status];
    } else {
      treeFilter = statuses ? ['in', ['get', 'tree_flow_status'], ['literal', statuses]] : ['==', ['get', 'tree_flow_status'], status];
    }

    return [
      'case',
      ['in', ['get', 'id'], ['literal', deletedTrees.map((tree) => parseInt(tree))]],
      false,
      status === 'maven' || treeFilter,
      filter,
      false,
    ];
  };

  const popup =
    treePopup ||
    (({ feature, ...props }) => {
      return <TreePopup tree={feature} {...props} />;
    });

  const filterLayers = layerFilter.map((layer) => {
    // custom filter modifications
    if (layer.source === 'safety_source' || layer.source === 'hyperleaf') {
      return {
        ...layer,
      };
    }

    const config = getConfig(`statuses.${layer.id}`);
    const filter = generateFilter(layer.id, layer.filter, layer.statuses);
    return {
      ...layer,
      onClick: _handleTreeSelectForMap,
      popup: popup,
      filter,
      label: config?.label || 'Unnamed',
      color: getConfig(`colors.${config?.color}`) || '#08f',
    };
  });

  const layers = [...filterLayers, maLayer];

  const [sourceVisible, setSourceVisible] = useState({
    mas: true,
    las: false,
    trees: true,
    maven: true,
    safety_source: true,
    hyperleaf: true,
  });

  const [defaultLayersVisible, setDefaultLayersVisible] = useState(layers.reduce((prev, curr) => ({ ...prev, [curr['id']]: true }), {}));

  const layerVisible = useMemo(() => {
    if (!defaultLayersVisible) {
      return {
        trees: sourceVisible.trees,
        mas: sourceVisible.mas,
        maven: sourceVisible.trees,
        sent_to_field: defaultLayersVisible.sent_to_field && sourceVisible.trees,
        validation_todo: defaultLayersVisible.validation_todo && sourceVisible.trees,
        validation_done: defaultLayersVisible.validation_done && sourceVisible.trees,
        location_validation_deleted: defaultLayersVisible.validation_deleted && sourceVisible.trees,
        safety_labels: defaultLayersVisible.safety_labels && sourceVisible.safety_source && sourceVisible.trees,
        below: defaultLayersVisible.below && sourceVisible.trees,
        above: defaultLayersVisible.above && sourceVisible.trees,
        qc_status: defaultLayersVisible.qc_status && sourceVisible.trees,
        [QC_STATUSES.QC_PENDING]: defaultLayersVisible[QC_STATUSES.QC_PENDING] && sourceVisible.trees,
        [QC_STATUSES.QC_ERROR]: defaultLayersVisible[QC_STATUSES.QC_ERROR] && sourceVisible.trees,
        [QC_STATUSES.QC_MODIFIED]: defaultLayersVisible[QC_STATUSES.QC_MODIFIED] && sourceVisible.trees,
        [QC_STATUSES.QC_APPROVED]: defaultLayersVisible[QC_STATUSES.QC_APPROVED] && sourceVisible.trees,
        null: defaultLayersVisible['null'] && sourceVisible.trees,
      };
    }

    return {
      ...defaultLayersVisible,
      trees: sourceVisible.trees,
      mas: sourceVisible.mas,
      maven: sourceVisible.trees,
      sent_to_field: defaultLayersVisible.sent_to_field && sourceVisible.trees,
      validation_todo: defaultLayersVisible.validation_todo && sourceVisible.trees,
      validation_done: defaultLayersVisible.validation_done && sourceVisible.trees,
      location_validation_deleted: defaultLayersVisible.validation_deleted && sourceVisible.trees,
      safety_labels: defaultLayersVisible.safety_labels && sourceVisible.safety_source && sourceVisible.trees,
      below: defaultLayersVisible.below && sourceVisible.trees,
      above: defaultLayersVisible.above && sourceVisible.trees,
      tree_flow_status: defaultLayersVisible.tree_flow_status && sourceVisible.trees,
      qc_status: defaultLayersVisible.qc_status && sourceVisible.trees,
      [QC_STATUSES.QC_PENDING]: defaultLayersVisible[QC_STATUSES.QC_PENDING] && sourceVisible.trees,
      [QC_STATUSES.QC_ERROR]: defaultLayersVisible[QC_STATUSES.QC_ERROR] && sourceVisible.trees,
      [QC_STATUSES.QC_MODIFIED]: defaultLayersVisible[QC_STATUSES.QC_MODIFIED] && sourceVisible.trees,
      [QC_STATUSES.QC_APPROVED]: defaultLayersVisible[QC_STATUSES.QC_APPROVED] && sourceVisible.trees,
      null: defaultLayersVisible['null'] && sourceVisible.trees,
    };
  }, [defaultLayersVisible]);

  return (
    <Skeleton
      header={{
        headerTitle: isMicroClimate
          ? title
          : isQualityCheck
          ? title
          : pipeline?.current_manual_step
          ? getHeaderTitle(pipeline?.current_manual_step)
          : title,
        navTabs: generateNavigationTabs(managedArea?.id, managedArea?.code, currentTreeId, handleChangeTree),
      }}
      tabs={[
        {
          title: 'Map',
          icon: 'singapore',
          path: `/${basePath}`,
          exact: true,
        },
        {
          title: 'Managed Area',
          icon: 'map-marked-alt',
          disabled: !managedArea?.id,
          path: `/${basePath}/${managedArea?.id}`,
        },
      ]}
    >
      <Switch>
        {screens
          .filter((screen) => !!screen)
          .map((screen) => (
            <Route
              key={screen.path}
              title={screen.title}
              path={screen.path}
              render={(props) => (
                <screen.Component
                  {...{
                    ...props,
                    ...screen.props,
                    trees: sortedTrees?.length ? sortedTrees : trees,
                    todoTrees,
                    managedAreas,
                    pipelines: sortedPipelines?.length ? sortedPipelines : pipelines,
                    selection,
                    mapRef,
                    layerSources,
                    isToolbarVisible,
                    setIsToolbarVisible,
                    loaded: props.loaded,
                    managedArea,
                    contextsLoadingState,
                    activeTabs,
                    setActiveTabs,
                    setOfficerInCharge,
                    reloadTrees: betaMACtx.fetchTrees,
                    isMicroClimate,
                    isQualityCheck,
                    refetchQualityCheckTrees,
                    selectTree,
                    moveToNeighbour,
                    getNextTree,
                    sourceVisible,
                    mapLayers: layers,
                    filterLayers,
                    layerVisible,
                  }}
                />
              )}
            />
          ))}

        <Route
          title={`${managedArea?.code || managedArea?.id || 'Overview'} | ${title}`}
          path={`/${basePath}/:MA?`}
          render={(props) => (
            <ManagedAreaMap
              {...{
                ...props,
                basePath,
                mapRef,
                selection,
                treeStatusFilter,
                customColumns,
                maStatusFilter,
                resetFocus: _handleMAFocus,
                focusOnMA: _handleMapFocus,
                selectedTree,
                setSelectedTree,
                layerSources,
                sliders,
                startAction,
                controls,
                tableHeader,
                proximityAlertTreeIDs,
                setProximityAlertTreeIDs,
                fullHeight: true,
                screenType,
                trees: sortedTrees?.length ? sortedTrees : trees,
                todoTrees,
                uploadQCTreesModalOpen,
                setUploadQCTreesModalOpen,
                refetchQualityCheckTrees,
                pipeline,
                pipelines: sortedPipelines?.length ? sortedPipelines : pipelines,
                contextsLoadingState,
                managedArea,
                tableSortOptions,
                handleTableSortChange,
                handleTableConfigChange,
                setMaFilter,
                mapLayers: layers,
                filterLayers,
                sourceVisible,
                setSourceVisible,
                layerVisible,
                setLayerVisible: setDefaultLayersVisible,
                isQualityCheck,
              }}
            />
          )}
        />
      </Switch>
    </Skeleton>
  );
};

export default SkeletonWithManagedAreaMap;
