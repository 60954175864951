import { useCursor } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Color, Euler, Mesh, Vector3 } from 'three';
import { DragControls } from 'three/examples/jsm/controls/DragControls';
import config from '../../config';
import DepthRect from './DepthRect';

type HeightGizmoProps = {
  name: string;
  color: string;
  positionZ: number;
  onChange?: (height: number) => void;
  draggable: boolean;
  activeTool?: string;
  onGrabbed?: React.Dispatch<React.SetStateAction<string | null>>;
  lineWidth?: number;
  visible?: boolean;
  depth?: number;
  viewerPosition?: Vector3;
  allowBelowZeroZ?: boolean;
};

const HeightGizmo = ({
  name,
  color,
  visible = true,
  positionZ,
  onChange,
  draggable,
  activeTool,
  onGrabbed,
  lineWidth,
  depth,
  allowBelowZeroZ
}: HeightGizmoProps) => {
  const { camera, gl } = useThree();
  const ref = useRef<Mesh>(null);
  const controlsRef = useRef<DragControls>();
  const outlineRef = useRef<Mesh>(null);
  const [hover, setHover] = useState(false);

  const handleDrag = useCallback((e: any) => {
    onChange && onChange(e.object.position.z);
  }, [onChange]);

  const handleDragStart = useCallback((e: any) => {
    onGrabbed?.(name);
  }, [onGrabbed, name]);

  // Change pointer style on grab
  useCursor(hover, 'grab', 'auto');

  // Attach drag event listener
  useEffect(() => {
    if (!ref.current || !draggable) return;

    const controlledObject = outlineRef.current ? [outlineRef.current] : [ref.current];
    controlsRef.current = new DragControls(controlledObject, camera, gl.domElement);

    return () => controlsRef?.current?.dispose();
  }, [draggable, camera, gl.domElement, ref]);

  // Attach drag event listener
  useEffect(() => {
    if (!controlsRef.current) return;

    controlsRef.current.addEventListener('drag', handleDrag);
    controlsRef.current.addEventListener('dragstart', handleDragStart);

    return () => {
      if (!controlsRef.current) return;

      controlsRef.current.removeEventListener('drag', handleDrag);
      controlsRef.current.removeEventListener('dragstart', handleDragStart);
    };
  }, [handleDrag, handleDragStart]);

  // Setting height on state update
  useEffect(() => {
    if (!ref.current) return;

    if (!allowBelowZeroZ && positionZ < 0) {
      ref.current.position.z = 0;
    }
    else {
      ref.current.position.z = positionZ ?? 0;
    }
  }, [positionZ, allowBelowZeroZ]);

  // Rotate the plane to face the camera
  useFrame(() => {
    if (!ref.current) return;
    ref.current.quaternion.copy(camera.quaternion);
  });

  useFrame(() => {
    if (!ref.current) return;
    ref.current.visible = visible;

    if (!outlineRef.current) return;
    outlineRef.current.position.copy(ref.current.position);
    outlineRef.current.quaternion.copy(ref.current.quaternion);
  });

  const zVector3 = new Vector3(0, 0, positionZ ?? 0);

  useFrame(({ camera }) => {
    if (!ref.current) return;
    const scale = (1 / camera.zoom) * 50;
    ref.current.scale.set(1, scale, 1);

    if (!outlineRef.current) return;
    outlineRef.current?.scale.set(1, scale, 1);
  });

  const gizmoLineWidth = lineWidth ?? config.heightLineWidth;
  const outlineLineWidth = gizmoLineWidth * 4;

  return (
    <>
      <group name='grouped-strike'>
        <mesh ref={ref} name={name} renderOrder={999} rotation={new Euler(Math.PI / 2, 0, 0)} frustumCulled={false}>
          <planeGeometry args={[300, gizmoLineWidth]} />
          <meshBasicMaterial color={new Color(color)} depthTest={false} depthWrite={false} />
        </mesh>
        <mesh
          visible={draggable && (hover || name === activeTool)}
          ref={outlineRef}
          renderOrder={999}
          rotation={new Euler(Math.PI / 2, 0, 0)}
          onPointerEnter={() => {
            if (!draggable) return;

            setHover(true);
          }}
          onPointerDown={() => {
            if (!draggable) return;

            controlsRef.current!.enabled = true;
          }}
          onPointerLeave={() => {
            if (!draggable) return;

            setHover(false);
          }}
        >
          <planeGeometry args={[300, outlineLineWidth]} />
          <meshBasicMaterial transparent opacity={0.1} />
        </mesh>
      </group>
      {depth && <DepthRect position={zVector3} depth={depth} visible={visible} />}
    </>
  );
};

export default HeightGizmo;
