import { useCallback } from 'react';
import Tree from '../../@types/Tree';

export interface BetaTreeIndexValue {
  selectTree: (treeId: string) => void;
  moveToNeighbour: (direction?: number) => void;
  getNextTree: (direction?: number) => Partial<Tree> | null;
}

export interface UseBetaTreeIndexProps {
  trees: Partial<Tree>[];
  todoTreesFilter: (tree: Partial<Tree>) => boolean;
  skippedTrees?: string[];
  currentTreeId: string;
  setTree: React.Dispatch<React.SetStateAction<Partial<Tree> | null>>;
}

export const useBetaTreeIndex = ({
  trees,
  todoTreesFilter,
  currentTreeId,
  setTree,
  skippedTrees,
}: UseBetaTreeIndexProps): BetaTreeIndexValue => {

  const selectTree = useCallback((treeId: string) => {
    if (!trees) return;

    const index = trees.findIndex((tree) => Number(tree.id) === Number(treeId));
    if (index > -1) {
      setTree(trees[index]);
    }
  }, [setTree, trees]);

  const getNextTree = useCallback((direction = 1): Partial<Tree> | null => {
    if (!trees) return null;

    const currentTreeIndex = trees.findIndex((tree) => tree.id === currentTreeId);
    const startIndex = currentTreeIndex !== -1 ? currentTreeIndex : 0;

    const findNextTreeIndex = (filterSkippedTrees: boolean) => {
      let nextIndex = (startIndex + direction + trees.length) % trees.length;

      // skippedTreeExpression will be true if we need to filter skipped trees and the
      // current tree is in the list.
      const skippedTreeExpression = filterSkippedTrees && direction === 1 && 
        skippedTrees?.includes(trees[nextIndex].id!);
      // Loop until we find a suitable tree, or reach the tree we started from
      while ((!todoTreesFilter(trees[nextIndex]) || skippedTreeExpression) && 
        nextIndex !== startIndex)
      {
        nextIndex = (nextIndex + direction + trees.length) % trees.length;
      }

      return nextIndex;
    }

    let nextIndex = findNextTreeIndex(true);
    if (nextIndex === startIndex) {
      nextIndex = findNextTreeIndex(false);
    }

    // Diagnostics
    const todoTreeLength = trees.filter((t) => todoTreesFilter(t)).length;
    console.log(
      `Next tree info: { currentTreeId: ${currentTreeId}, nextTreeId: ${trees[nextIndex].id}, ` +
      `currentTreeIndex: ${currentTreeIndex}, startIndex: ${startIndex}, nextIndex: ${nextIndex}, ` +
      `trees.length: ${trees.length}, todoTreeLength: ${todoTreeLength}, ` +
      `skippedTrees.length: ${skippedTrees?.length} }`
    );

    return trees[nextIndex];
  }, [currentTreeId, skippedTrees, trees, todoTreesFilter]);

  const moveToNeighbour = useCallback((direction = 1) => {
    const nextTree = getNextTree(direction);
    if (nextTree) {
      setTree(nextTree);
    }
    else {
      const todoTreeLength = trees.filter((t) => todoTreesFilter(t)).length;
      console.error(
        `Could not find next tree (currentTreeId: ${currentTreeId}, ` + 
        `trees.length: ${trees.length}, todoTreeLength: ${todoTreeLength})`
      );
    }
  }, [getNextTree, setTree, currentTreeId, trees, todoTreesFilter]);

  return {
    selectTree,
    moveToNeighbour,
    getNextTree,
  };
};
