import { useState } from 'react';
import Icon from '../Icon';
import { StateSetter } from '../../@types/defaults';
import useApi from '../../hooks/api';
import { Button, TextInput } from '../inputs';
import { useQualityCheckedTreesContext } from '../../hooks/betaHooks/quality-checked-trees-context';

interface Props {
  uploadQCTreesModalOpen: boolean;
  setUploadQCTreesModalOpen: StateSetter<Boolean>;
}

const UploadQualityCheckTreesModal = ({ uploadQCTreesModalOpen, setUploadQCTreesModalOpen }: Props) => {
  const { handleRequest } = useApi();
  const [qcTreeIdList, setQCTreeIdList] = useState<string | null>(null);
  const { toasts, showToast } = useQualityCheckedTreesContext();

  if (!uploadQCTreesModalOpen) return null;

  const handleTextChange = (value: string) => {
    setQCTreeIdList(value);
  };

  const handleFileUpload = async () => {
    const treeIdListAsArray = qcTreeIdList?.replaceAll(/["' ]/g, '').replace(/[;,]+$/, '').split(/[;,]/);

    try {
      await handleRequest(`/v1/quality_check/upsert`, {
        method: 'PATCH',
        body: JSON.stringify({ treeIds: treeIdListAsArray }),
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (err: any) {
      showToast(`Failed to upload trees: \n\n ${err.message}`, 'error');
    } finally {
      showToast('Upload trees was successful!', 'info');
    }
  };

  return (
    <div className='uploadModalWrapper'>
      <div className='uploadModal'>
        <div className='header'>
          <p>Upload Quality Check Trees</p>
          <i className='close-button' onClick={() => setUploadQCTreesModalOpen(false)}>
            <Icon icon='removeX' />
          </i>
        </div>
        <div className='body'>
          <div className='helperText'>
            <p>You can input a list of treeIds in the following formats:</p>
            <ol>
              <li>
                <i>Comma-separated list: 123, 456, 789</i>
              </li>
              <li>
                <i>Semicolon-separated list: 123; 456; 789</i>
              </li>
              <li>
                It can contain strings as well, like these:
                <ul>
                  <li>
                    <i>Comma-separated list: "123", "456", "789"</i>
                  </li>
                  <li>
                    <i>Semicolon-separated list: "123"; "456"; "789"</i>
                  </li>
                  <li>
                    <i>Other Comma-separated list: '123', '456', '789'</i>
                  </li>
                  <li>
                    <i>Other Semicolon-separated list: '123'; '456'; '789'</i>
                  </li>
                </ul>
              </li>
              <li>
                Or any of the following mixed formats:
                <ul>
                  <li>
                    <i>Comma-separated list: "123", 456, "789"</i>
                  </li>
                  <li>
                    <i>Semicolon-separated list: 123; '456'; "789"</i>
                  </li>
                  <li>
                    <i>Other Comma-separated list: '123', 456, "789"</i>
                  </li>
                  <li>
                    <i>Other Semicolon-separated list: '123'; 456; "789"</i>
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <TextInput id='treeIdList' label='Tree Id List' value={qcTreeIdList || ''} onPureChange={handleTextChange} />
          <Button label='Upload' primary onClick={handleFileUpload} />
        </div>
      </div>
      {Boolean(toasts.length) && (
        <div className='toast-wrapper'>
          {toasts.map((toast, index) => (
            <div key={index} className={`toast ${toast.type} ${toasts.length - 1 === index ? 'last' : ''}`}>
              {toast.message}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UploadQualityCheckTreesModal;
