import { createBrowserHistory } from 'history';
import appConfig from '../config';
import { getProjectName } from '../core/projectSelector/projectSelector';
import { useAuth } from '../providers/auth-provider';
import { useMemo } from 'react';

const SERVER = appConfig.server.endpoint;

const handleRequest =
  (acquireToken: () => Promise<string>, allowThrowError = false, redirectIfError = true) =>
  async (url: string, opts = {}) => {
    const token = await acquireToken();
    const { url: outUrl, opts: outOpts } = createRequest(token, url, opts);

    return fetch(outUrl, outOpts)
      .then(async (response) => {
      if (!response.ok) {
        if (allowThrowError) {
          throw new Error(await response.text());
        }

        const history = createBrowserHistory({ forceRefresh: true });

        if (redirectIfError) {
          if (history.location.pathname !== '/error-occurred' && response.status === 500) {
            history.push('/error-occurred');
          } else if (response.status === 401) {
            response.text().then((body) => {
              if (body === 'Invalid RTMS token.') {
                history.push('/error-multi-login');
              }
            });
          }
        }

        return {
          json: () => ({ error: true, status: response.status }),
        };
      }
      return response;
    }).catch((err) => {
        return {
          json: () => ({ error: true, status: 500 }),
        };
      })
  };

const createRequest = (token: string, url: string, opts: any) => {
  return {
    url: getUrl(url),
    opts: {
      ...opts,
      headers: {
        ...(opts.headers || {}),
        ...generateHeaders(token),
      },
    },
  };
};

export const getUrl = (url: string) => {
  const urlWithDomain = url.match(/^https?:\/\//) ? url : getUrlForEndpoint(url);
  const urlObject = new URL(urlWithDomain.trim());
  return urlObject.toString().trim();
};

export const getUrlForEndpoint = (endpoint: string) => {
  return SERVER + (endpoint.match(/^\//) ? '' : '/') + endpoint;
};

export const generateHeaders = (token?: string) => {
  const adRelatedHeaders = token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {};
  const projectName = getProjectName();
  return { ...adRelatedHeaders, project: projectName };
};

const useApi = () => {
  const { acquireToken } = useAuth();

  const apiFunctions = useMemo(() => ({
    handleRequest: handleRequest(acquireToken),
    requestHeader: generateHeaders,
    getUrl: getUrl,
  }), [acquireToken]);

  return apiFunctions;
};

export default useApi;
