import { useEffect } from 'react';
import { Route } from 'react-router';

const RouteWithTitle = ({ title, ...props }) => {

  useEffect(() => {
    if (title) {
      document.title = `${title} | IVT`
    }
    else {
      document.title = 'IVT'
    }
  }, [title]);

  return <Route { ...props } />;
}

export default RouteWithTitle;
