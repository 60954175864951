import { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { AccountInfo, AuthError, InteractionRequiredAuthError, InteractionStatus, PublicClientApplication } from "@azure/msal-browser";
import appConfig from '../config';

const msalConfig = {
  auth: {
    clientId: appConfig.auth.clientId,
    authority: appConfig.auth.authority,
    redirectUri: "/login",
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: true,    
  }
};

const loginRequest = {
  scopes: [appConfig.auth.scope]
};

const msalInstance = new PublicClientApplication(msalConfig);

interface AuthContextValue {
  initialized: boolean;
  authenticated: boolean;
  acquireToken: () => Promise<string>;
  getLastAccessToken: () => string;
  account: AccountInfo;
  login: () => void;
  logout: () => void;
}

interface AuthProviderProps {
  children?: React.ReactNode;
};

const AuthContext = createContext<AuthContextValue | null>(null);

const InnerAuthProvider = ({ children } : AuthProviderProps) => {
  const { instance, accounts, inProgress } = useMsal();
  const authenticated = useIsAuthenticated();
  const [initialized, setInitialized] = useState(false);
  const lastAccessToken = useRef('');

  const acquireToken = useCallback(async (): Promise<string> => {
    let accessToken = '';

    try {
      if (accounts.length === 0) {
        throw new AuthError('Account information is not available.');
      }

      const response = await instance.acquireTokenSilent({ ...loginRequest, account: accounts[0] });
      lastAccessToken.current = response.accessToken;
      accessToken = response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        if (inProgress === InteractionStatus.None) {
          instance.acquireTokenRedirect({ ...loginRequest, account: accounts[0] });
        }
      } else {
        console.error("Token acquisition failed", error);
      }
    }

    return accessToken;
  }, [accounts, instance, inProgress]);

  useEffect(() => {
    if (!initialized && inProgress === InteractionStatus.None) {
      setInitialized(true);
    }
  }, [inProgress, initialized]);

  // This is a hacky way to make the mapbox proxying work. This should not be 
  // used for anything else. Access tokens should always be acquired just before 
  // making a fetch request.
  const getLastAccessToken = () => lastAccessToken.current;

  useEffect(() => {
    let timer: any = null;
    if (authenticated && accounts.length > 0) {
      acquireToken();
      // Request a new token every 10 minutes
      timer = setInterval(acquireToken, 10*60*1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
        timer = null;
      }
    };
  }, [authenticated, accounts, acquireToken]);

  const login = () => {
    instance.loginRedirect({ ...loginRequest, redirectStartPage: window.location.href });
  };

  const logout = () => {
    instance.logoutRedirect();
  };

  const value: AuthContextValue = {
    initialized,
    authenticated,
    acquireToken,
    getLastAccessToken,
    account: accounts[0],
    login: login,
    logout: logout
  }

  return (
    <MsalProvider instance={msalInstance}>
      <AuthContext.Provider value={value}>
        {children}
      </AuthContext.Provider>
    </MsalProvider>
  );
};

export const AuthProvider = ({ children } : AuthProviderProps) => {
  return (
    <MsalProvider instance={msalInstance}>
      <InnerAuthProvider>
        {children}
      </InnerAuthProvider>
    </MsalProvider>
  );
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('No AuthProvider found when calling useAuth()');
  }

  return authContext;
};

interface WithAuthProps {
  auth: AuthContextValue;
};

// Context wrapper for creating HOCs from legacy React class components
export function withAuth<T extends WithAuthProps>(
  WrappedComponent: React.ComponentType<T>
): React.FC<Omit<T, keyof WithAuthProps>> {
  return function WithAuthWrapper(props) {
    const auth = useAuth();
    return <WrappedComponent {...(props as T)} auth={auth} />;
  };
}
