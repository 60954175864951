import React, { Fragment, PropsWithChildren, useEffect } from 'react';
import Icon from './Icon';
import Button from './inputs/Button';
import { useHistory } from 'react-router-dom';

interface Props {
  finished: boolean;
  reloadManagedAreas: () => Promise<void>;
  backButtonAction?: () => void;
  handleCompletedOnlineAnnotatedTrees?: () => void;
  handleCompletedSemanticTrees?: () => void;
}

const Finished = ({
  finished,
  backButtonAction,
  reloadManagedAreas = async () => {},
  handleCompletedOnlineAnnotatedTrees = () => void 0,
  handleCompletedSemanticTrees = () => void 0,
}: Props) => {
  const history = useHistory();

  useEffect(() => {
    if (!finished) return;

    handleCompletedOnlineAnnotatedTrees();
    handleCompletedSemanticTrees();
  }, [finished]);

  const _handleBack = async () => {
    await reloadManagedAreas();

    history.push(`/validation`);
  };

  return (
    <div className='finished-wrapper'>
      <Icon icon='rtms' />
      <Button label='Back to the map' onClick={backButtonAction || _handleBack} />
    </div>
  );
};

interface WrapperProps extends PropsWithChildren {
  finished: boolean;
  reloadManagedAreas: () => Promise<void>;
  backButtonAction?: () => void;
  handleCompletedOnlineAnnotatedTrees?: () => void;
  handleCompletedSemanticTrees?: () => void;
}

const FinishedWrapper = ({
  children,
  finished,
  backButtonAction,
  reloadManagedAreas,
  handleCompletedOnlineAnnotatedTrees,
  handleCompletedSemanticTrees,
}: WrapperProps) => {
  return (
    <Fragment>
      {finished && (
        <Finished
          finished={finished}
          backButtonAction={backButtonAction}
          reloadManagedAreas={reloadManagedAreas}
          handleCompletedOnlineAnnotatedTrees={handleCompletedOnlineAnnotatedTrees}
          handleCompletedSemanticTrees={handleCompletedSemanticTrees}
        />
      )}
      {children}
    </Fragment>
  );
};

export default FinishedWrapper;
