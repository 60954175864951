import { Skeleton } from '../layout';
import { Icon } from '../components';
import { withRouter } from 'react-router-dom';

const ErrorMultiLogin = () => {

  return (
    <Skeleton header={{ title: null, user: {} }}>
      <div className="notfound-wrapper">
        <Icon icon='rtms' />
        <h1>Concurrent logins for the same user is not allowed.</h1>
        <p></p>
      </div>
    </Skeleton>
  )
}

export default withRouter(ErrorMultiLogin);