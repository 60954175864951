import React, { createContext, useContext, useState } from 'react';

const STORAGE_KEY = '@theme.isDark';

type ThemeContextType = {
  isDark: boolean;
  toggleTheme: () => void;
  setTheme: (isDark: boolean) => void;
};

export const Context = createContext<ThemeContextType>({} as any);

export const useTheme = () => useContext(Context);

export const withTheme = (Component: React.FC) => (props: any) =>
  <Context.Consumer>{(theme) => <Component {...props} theme={theme} />}</Context.Consumer>;

export const Provider = (props: any) => {
  const [isDark, setIsDark] = useState(localStorage.getItem(STORAGE_KEY) === null ? true : localStorage.getItem(STORAGE_KEY) === 'true');

  /**
   * Toggle the application's current theme
   */
  const toggleTheme = () => _handleThemeSet(!isDark);

  /**
   * Set's the current theme
   * @param isDark: Whether to set the theme to dark
   */
  const setTheme = (isDark: boolean) => _handleThemeSet(isDark);

  // Setting theme, updating local storage
  const _handleThemeSet = (isDark: boolean) => {
    setIsDark(isDark);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(isDark));
  };

  return (
    <Context.Provider value={{ isDark, toggleTheme, setTheme }}>
      <main className={`page-wrapper ${isDark ? 'dark' : 'light'}`}>{props.children}</main>
    </Context.Provider>
  );
};
