import * as THREE from 'three';

const renderGrid = (scene: THREE.Scene, position: THREE.Vector3) => {
  const group = new THREE.Group();

  const grid = new THREE.GridHelper(100, 100);
  group.position.fromArray(position.clone().toArray());

  group.add(grid);
  scene.add(group);

  const tiltAngle = 180 * (Math.PI / 180); // 180 degrees in radians
  const tiltQuaternion = new THREE.Quaternion().setFromEuler(new THREE.Euler(tiltAngle / 2, 0, 0));

  return {
    remove: () => {
      grid.dispose();
      group.remove(grid);
    },
    syncRotation: (camera: THREE.Camera) => {
      group.quaternion.fromArray(camera.quaternion.clone().toArray());
      group.quaternion.multiply(tiltQuaternion);
    },
    syncPosition: (position: THREE.Vector3, camera: THREE.OrthographicCamera) => null,
  };
}

export default renderGrid;

