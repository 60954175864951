import { minBy, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounceTime, fromEvent } from 'rxjs';
import { Group, MathUtils, Mesh, MeshBasicMaterial, PerspectiveCamera, RingGeometry, Scene, SphereGeometry, Vector2, Vector3 } from 'three';
import { getProjectYear } from '../../core/projectSelector/projectSelector';
import { useBetaManagedAreaContext } from '../../hooks/betaHooks/beta-managed-area-context';
import { CapturePoint } from './libs/capture-point';
import { CapturePointConnection } from './libs/capture-point-connection';
import { createAddTreeTreeLock, createOtherTreeLock } from './libs/debug-tree-lock';
import { PanoramaControl } from './libs/panorama-control';
import { PanoramaImageSphere } from './libs/panorama-image-sphere';
import { PanoramaImageTile } from './libs/panorama-image-tile';
import { SpherePanoramaObject } from './libs/sphere-panorama.object';
import { TilePanoramaObject } from './libs/tile-panorama.object';
import { TreeLock } from './libs/tree-lock';
import { useResponsiveThreeRenderer } from './libs/use-responsive-three-renderer';
import useApi from '../../hooks/api';

export interface NewPanoramaViewProps {
  treeId: any;
  managedAreaCode: any;
  exactSnapshotId: string;
  onCapturePointClick?: (data: any) => void;
  onTreeClick?: (data: any) => void;
  onInitialCapturePointDetected?: (data: any) => void;
  optionalPosition?: Vector3;
  addTreeMode?: boolean;
  onAddTree?: (position: any) => void;
  onContextChanged?: (data: any) => void;
  onViewChange?: (data: any) => void;
  setCameraPropsForGoogleMaps?: (data: any) => void;
  virtualTreePosition?: Vector3;
}

async function measureElapsedTime(config: { tag: string, metric_type: string, tree_id: string, managed_area: string }, handleRequest: any, asyncFn: any) {
  const start = Date.now();
  await asyncFn();
  const end = Date.now();
  const elapsedTime = end - start;

  try {
    handleRequest('/metrics', {
      method: 'POST',
      body: JSON.stringify({ ...config, time: elapsedTime }),
      headers: { 'Content-Type': 'application/json' }
    })
  } catch {
    console.log('Metrics log failed');
  }
}

export const NewPanoramaView = (props: NewPanoramaViewProps) => {
  const CAPTURE_POINT_RELATIVE_HEIGHT = -2;
  const MAX_DISTANCE_BETWEEN_CAPTURE_POINT_TO_CONNECT = 20;

  const { handleRequest } = useApi();
  const { pipeline } = useBetaManagedAreaContext();

  const [capturePoints, setCapturePoints] = useState<any[]>([]);
  const [trees, setTrees] = useState<any[]>([]);

  const scanYear = getProjectYear();
  const queryYear = !!scanYear ? `scanYear=${scanYear}` : ('latest=1');

  const fetchData = useCallback(async () => {
    const response = await handleRequest(`/v1/capture_points_v2_by_managed_area/${props.managedAreaCode}?${queryYear}`)
      .then((r) => r.json());

    setCapturePoints(response?.capturePoints || []);
    setTrees(response?.trees || []);
  }, [handleRequest, props.managedAreaCode, queryYear])

  const otherTrees = useMemo(() => {
    return trees?.filter((item) => item.id !== props.treeId);
  }, [trees, props.treeId]);

  const currentTree = useMemo(() => {
    return trees?.find((item) => item.id === props.treeId);
  }, [trees, props.treeId]);

  const currentTreePosition = useMemo(() => new Vector3().fromArray(currentTree?.position?.coordinates || [0, 0, 0]), [currentTree?.position?.coordinates])

  const currentCapturePoint = useMemo<any>(() => {
    if (props.exactSnapshotId) {
      return capturePoints.find((item: any) => item.snapshot_id === props.exactSnapshotId);
    }

    const result = minBy(capturePoints, (item) => {
      const [x1, y1] = [item.position?.coordinates?.[0] || 0, item.position?.coordinates?.[1] || 0];
      const [x2, y2] = [currentTreePosition.x || 0, currentTreePosition.y || 0];

      return Math.sqrt(
        Math.pow(x2 - x1, 2)
        + Math.pow(y2 - y1, 2)
      );
    })

    return result;
  }, [capturePoints, currentTreePosition.x, currentTreePosition.y, currentTreePosition.z, props.exactSnapshotId])

  useEffect(() => {
    if (!currentCapturePoint) return;
    if (!currentTree?.id) return;

    props.onInitialCapturePointDetected?.(currentCapturePoint);
  }, [!!currentCapturePoint, currentTree?.id]);

  const currentCapturePointPosition = useMemo(() => new Vector3().fromArray(currentCapturePoint?.position?.coordinates || [0, 0, 0]), [currentCapturePoint])

  useEffect(() => {
    fetchData();
  }, [props.managedAreaCode]);

  const camera = useMemo(() => {
    const camera = new PerspectiveCamera(100, 1, 0.1, 1000);
    camera.up.set(0, 0, 1);
    return camera;
  }, []);

  const scene = useMemo(() => new Scene(), []);

  const [controls, setControl] = useState<any | null>(null)

  const { rayCaster, canvasRef, canvasDOMElement, frontRenderer, aspectRatio, ready } = useResponsiveThreeRenderer({
    onRender: (ctx) => {
      ctx.render(scene, camera);
    },
    webGLRendererOptions: {
      // logarithmicDepthBuffer: true
    }
  });

  useEffect(() => {
    if (!ready) return;
    if (!camera) return;

    camera.aspect = aspectRatio;
    camera.updateProjectionMatrix();
  }, [ready, aspectRatio, !!camera]);

  useEffect(() => {
    if (!camera) return;
    if (!scene) return;
    if (!frontRenderer) return;

    const controls = new PanoramaControl(camera, frontRenderer.domElement);

    setControl(controls);

    return () => {
      controls.dispose();
    }
  }, [!!camera, !!frontRenderer, !!scene])

  useEffect(() => {
    const subscription = controls?.subject?.subscribe(([phi, theta, fov]: any) => {
      props.onViewChange?.({
        fov,
        phi,
        theta,
        position: currentCapturePointPosition.clone(),
        rotation: new Vector2(phi, theta),
        rotationDeg: new Vector2(MathUtils.radToDeg(phi), MathUtils.radToDeg(theta))
      })

      props.setCameraPropsForGoogleMaps?.({
        fov,
        phi,
        theta
      })
    })

    return () => {
      subscription?.unsubscribe();
    }
  }, [!!camera, currentCapturePointPosition.x, currentCapturePointPosition.y, currentCapturePointPosition.z, controls?.subject, props]);

  /**
   * ########## TREE LOCATION SECTION ###
   */

  const treeLockObject = useMemo<TreeLock>(() => new TreeLock(), []);
  const virtualTreeLockObject = useMemo<Group>(() => createAddTreeTreeLock(), []);

  useEffect(() => {
    scene.add(virtualTreeLockObject)
  }, [!!scene, !!virtualTreeLockObject])

  useEffect(() => {
    if (!currentCapturePointPosition.x) return;
    if (!currentCapturePointPosition.y) return;
    if (!currentCapturePointPosition.z) return;
    if (!props?.virtualTreePosition?.x) return;
    if (!props?.virtualTreePosition?.y) return;
    if (!props?.virtualTreePosition?.z) return;

    const vPosition = props?.virtualTreePosition.clone().sub(currentCapturePointPosition.clone());

    virtualTreeLockObject.position.copy(vPosition)

  }, [!!scene, !!virtualTreeLockObject, currentCapturePointPosition, props.virtualTreePosition])

  useEffect(() => {
    scene.add(treeLockObject);
  }, [!!scene, !!treeLockObject]);

  useEffect(() => {
    if (!ready) return;
    if (!camera) return;
    if (!controls) return;
    if (!scene) return;

    const treePosition = currentTreePosition.clone().sub(currentCapturePointPosition.clone());

    treeLockObject.initPosition(treePosition);
  }, [
    !!ready,
    !!camera,
    !!scene,
    !!controls,
    currentTreePosition.x,
    currentTreePosition.y,
    currentTreePosition.z,
    currentCapturePointPosition.x,
    currentCapturePointPosition.y,
    currentCapturePointPosition.z
  ]);

  useEffect(() => {
    if (!ready) return;
    if (!camera) return;
    if (!controls) return;
    if (!scene) return;
    if (!props.optionalPosition) return;

    const treePosition = props.optionalPosition.clone().sub(currentCapturePointPosition.clone());

    treeLockObject.setRealTreeLockPosition(treePosition);
  }, [
    !!ready,
    !!camera,
    !!scene,
    !!controls,
    props.optionalPosition?.x,
    props.optionalPosition?.y,
    props.optionalPosition?.z,
    currentCapturePointPosition.x,
    currentCapturePointPosition.y,
    currentCapturePointPosition.z
  ]);

  useEffect(() => {
    if (!ready) return;
    if (!camera) return;
    if (!controls) return;
    if (!scene) return;
    if (!currentCapturePointPosition) return;
    if (!currentCapturePoint?.snapshot_id) return;

    let positionToLookAt = new Vector3();
    if (props?.virtualTreePosition?.x && props?.virtualTreePosition?.y && props?.virtualTreePosition?.z) {
      positionToLookAt = props.virtualTreePosition.clone().sub(currentCapturePointPosition.clone());
    } else {
      positionToLookAt = currentTreePosition.clone().sub(currentCapturePointPosition.clone());
    }

    controls.lookAt(positionToLookAt.clone());
  }, [
    !!ready,
    !!camera,
    !!scene,
    !!controls,
    !!currentTree,
    !!currentTreePosition,
    !!currentCapturePointPosition,
    currentCapturePoint?.snapshot_id,
    props?.virtualTreePosition?.x,
    props?.virtualTreePosition?.y,
    props?.virtualTreePosition?.z,
  ]);

  /**
   * ########## END OF TREE LOCATION SECTION ###
   */

  /**
   * ########## CAPTURE POINTS SECTION ###
   */
  useEffect(() => {
    if (!ready) return;
    if (!camera) return;
    if (!scene) return;
    if (!canvasRef) return;

    const ts: Mesh[] = [];

    for (const item of otherTrees) {
      const t = createOtherTreeLock(item);
      const tPosition = new Vector3().fromArray(item.position.coordinates).add(new Vector3(0, 0, 0))
      t.position.fromArray(tPosition.clone().sub(currentCapturePointPosition.clone()).toArray())

      ts.push(t)
    }

    const listener = fromEvent(canvasRef, 'dblclick')
      .subscribe(() => {
        if (props.addTreeMode) return;

        const clickedT = rayCaster.intersectObjects(ts)?.[0];

        if (clickedT) {
          props?.onTreeClick?.(clickedT);
        }
      });

    if (ts.length > 0) {
      scene.add(...ts);
    }

    return () => {
      if (ts.length > 0) {
        scene.remove(...ts);
      }
      listener.unsubscribe();
    }
  }, [
    !!ready,
    !!camera,
    !!scene,
    !!canvasRef,
    props.addTreeMode,
    otherTrees,
    currentCapturePointPosition?.x,
    currentCapturePointPosition?.y,
    currentCapturePointPosition?.z,
  ]);

  useEffect(() => {
    if (!ready) return;
    if (!camera) return;
    if (!scene) return;
    if (!canvasRef) return;
    if (!controls) return;

    const cps: CapturePoint[] = [];
    const conns: CapturePointConnection[] = [];

    const sorted = sortBy(capturePoints, (item) => item?.data?.[0]?.timestamp);

    for (const item of sorted) {
      const cp = new CapturePoint(item);
      const cpPosition = new Vector3().fromArray(item.position.coordinates).add(new Vector3(0, 0, CAPTURE_POINT_RELATIVE_HEIGHT))
      cp.position.fromArray(cpPosition.clone().sub(currentCapturePointPosition.clone()).toArray())

      const last = cps?.[cps.length - 1];

      if (last) {
        const distance = Math.sqrt(
          Math.pow(last.position.clone().x - cp.position.clone().x, 2)
          + Math.pow(last.position.clone().y - cp.position.clone().y, 2)
        );

        if (distance < MAX_DISTANCE_BETWEEN_CAPTURE_POINT_TO_CONNECT) {
          conns.push(new CapturePointConnection(last, cp));
        }
      }

      cps.push(cp);
    }

    if (conns.length > 0) {
      scene.add(...conns);
    }
    if (cps.length > 0) {
      scene.add(...cps);
    }

    const listeners = [
      fromEvent(canvasRef, 'dblclick')
        .subscribe(() => {
          if (props.addTreeMode) return;

          const clickedCp = rayCaster.intersectObjects(cps)?.[0];
          const clickedConn = rayCaster.intersectObjects(conns)?.[0];

          if (clickedCp || clickedConn) {
            props?.onCapturePointClick?.(clickedCp || clickedConn);
          }
        }),
      fromEvent(canvasRef, 'mousemove')
        .subscribe(() => {
          const hoverConn: any = rayCaster.intersectObjects(conns)?.[0];

          for (const item of conns) {
            (hoverConn?.object?.userData?.data?.snapshot_id === item?.userData?.data?.snapshot_id)
              ? item.markHover()
              : item.restoreHover()
          }

          const hoverCp: any = rayCaster.intersectObjects(cps)?.[0];

          for (const item of cps) {
            (hoverCp?.object?.userData?.data?.snapshot_id === item?.userData?.data?.snapshot_id)
              ? item.markHover()
              : item.restoreHover()
          }
        })
    ];


    return () => {
      if (conns.length > 0) {
        scene.remove(...conns);
      }
      if (cps.length > 0) {
        scene.remove(...cps);
      }

      for (const item of listeners) {
        item.unsubscribe();
      }
    }
  }, [
    !!ready,
    !!camera,
    !!scene,
    !!canvasRef,
    !!controls,
    props.addTreeMode,
    capturePoints,
    currentCapturePointPosition?.x,
    currentCapturePointPosition?.y,
    currentCapturePointPosition?.z,
  ]);

  /**
   * ########## END OF CAPTURE POINTS SECTION ###
   */

  /**
   * ########## Add tree section
   */

  const addTreeLockObject = useMemo(() => createAddTreeTreeLock(), []);

  const addTreeLockPlaneObject = useMemo(() => new Mesh(
    new RingGeometry(0, 1000, 5, 5),
    new MeshBasicMaterial()
  ), []);

  // Add objects to the scene

  useEffect(() => {
    if (!ready) return;
    if (!camera) return;
    if (!scene) return;
    if (!canvasRef) return;

    addTreeLockObject.position.set(0, 0, CAPTURE_POINT_RELATIVE_HEIGHT);
    addTreeLockPlaneObject.position.set(0, 0, CAPTURE_POINT_RELATIVE_HEIGHT);
    addTreeLockPlaneObject.visible = false;

    scene.add(addTreeLockObject);
    scene.add(addTreeLockPlaneObject);

    return () => {
      scene.remove(addTreeLockObject);
      scene.remove(addTreeLockPlaneObject);
    }
  }, [
    !!ready,
    !!camera,
    !!scene,
    !!canvasRef
  ]);

  useEffect(() => {
    if (!ready) return;
    if (!camera) return;
    if (!scene) return;
    if (!canvasRef) return;
    if (!rayCaster) return;

    if (!props.addTreeMode) {
      addTreeLockObject.visible = true;
      addTreeLockObject.position.set(-1000, -1000, -1000);

      return;
    }

    addTreeLockObject.visible = true;

    const position = new Vector3(0, 0, 0);

    const listeners = [
      fromEvent(canvasRef, 'mousemove')
        .subscribe(() => {
          const hit = rayCaster.intersectObject(addTreeLockPlaneObject)?.[0];

          if (!hit) return;

          addTreeLockObject.position.fromArray(hit.point.clone().toArray());
          position.set(hit.point.x, hit.point.y, hit.point.z);
        }),
      fromEvent(canvasRef, 'dblclick')
        .subscribe(() => {
          const absPosition = position.clone().add(currentCapturePointPosition.clone());

          props.onAddTree?.(absPosition);
        })
    ]

    return () => {
      for (const item of listeners) {
        item.unsubscribe();
      }
    }
  }, [
    !!ready,
    !!camera,
    !!scene,
    !!canvasRef,
    !!rayCaster,
    props.addTreeMode,
    currentCapturePointPosition.x,
    currentCapturePointPosition.y,
    currentCapturePointPosition.z,
  ]);

  const panoramaSingleImages = useMemo(() => new TilePanoramaObject(), [])
  const panoramaFullPanoramaImages = useMemo(() => new SpherePanoramaObject(), [])

  useEffect(() => {
    if (!scene) return;
    if (!panoramaSingleImages) return;
    if (!panoramaFullPanoramaImages) return;

    scene.add(panoramaSingleImages);
    scene.add(panoramaFullPanoramaImages);
  }, [!!scene, !!panoramaSingleImages, !!panoramaFullPanoramaImages]);

  /**
   * Load Panorama Images for SingleImage (Tile)
   */
  useEffect(() => {
    if (!ready) return;
    if (!camera) return;
    if (!scene) return;
    if (!currentCapturePoint?.data) return;
    if (currentCapturePoint?.type !== 'single_images') return;

    const capturePointIsFirstCapturePoint = capturePoints.findIndex(cp => cp.snapshot_id === currentCapturePoint.snapshot_id) === 0;
    const isThereSnapshotId = !!props.exactSnapshotId && !capturePointIsFirstCapturePoint;

    if (!isThereSnapshotId) return;

    panoramaSingleImages.position.set(0, 0, 0);

    measureElapsedTime(
      { tag: 'single-image', metric_type: 'panorama-load-new', tree_id: props.treeId, managed_area: pipeline?.code || "-" },
      handleRequest,
      async () => {
        await panoramaSingleImages.load({
          images: currentCapturePoint.data.map((item: any) => PanoramaImageTile.createFromServerResponse(item, pipeline?.code))
        });
      }
    );

    return () => {
      panoramaSingleImages.unload();
    };
  }, [!!ready, !!camera, !!scene, currentCapturePoint?.snapshot_id, pipeline?.code, props?.exactSnapshotId]);

  /**
   * Load Panorama Images for FullPano (Spherical)
   */
  useEffect(() => {
    if (!ready) return;
    if (!camera) return;
    if (!scene) return;
    if (!currentCapturePoint?.data) return;
    if (!currentCapturePoint?.data?.[0]) return;
    if (currentCapturePoint?.type !== 'full_pano_cam3_center') return;

    panoramaFullPanoramaImages.position.set(0, 0, 0)

    measureElapsedTime(
      { tag: 'full-panorama', metric_type: 'panorama-load-new', tree_id: props.treeId, managed_area: pipeline?.code || "-" },
      handleRequest,
      async () => {
        await panoramaFullPanoramaImages.load({
          image: PanoramaImageSphere.createFromServerResponse(currentCapturePoint?.data?.[0])
        });
      }
    );

    return () => {
      panoramaFullPanoramaImages.unload();
    }
  }, [!!ready, !!camera, !!scene, currentCapturePoint?.snapshot_id]);

  const emitContextChange = useCallback(() => {
    if (!camera) return;
    if (!currentCapturePoint) return;

    props.onContextChanged?.({ camera, capturePoint: currentCapturePoint, tree: currentTree });
  }, [camera, currentCapturePoint, currentTree])

  useEffect(() => {
    if (!canvasRef) return;
    if (!camera) return;
    if (!currentCapturePoint) return;

    emitContextChange();

    const listeners = [
      fromEvent(canvasRef, 'mousemove')
        .pipe(debounceTime(100))
        .subscribe(() => emitContextChange()),
      fromEvent(canvasRef, 'wheel')
        .pipe(debounceTime(100))
        .subscribe(() => emitContextChange())
    ];

    return () => {
      for (const item of listeners) {
        item.unsubscribe();
      }
    };
  }, [
    !!canvasRef,
    currentTree?.id,
    currentCapturePoint?.snapshot_id
  ]);

  return <>
    {canvasDOMElement}
  </>
}
