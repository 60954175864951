import { Skeleton } from '../layout';
import { Button } from '../components/inputs';
import { useAuth } from '../providers/auth-provider';
import { Redirect } from 'react-router';

const LoginPage = () => {

  const { authenticated, login } = useAuth();

  if (authenticated) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Skeleton header={{
      title: 'Login'
    }}>
      <div className="login-wrapper">
        <div>
          <Button primary onClick={login} label='Sign in using Azure AD' />
        </div>
      </div>
    </Skeleton>
  );
}

export default LoginPage;
