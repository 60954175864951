import { Fragment, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { Route } from '../layout';
import { Loader, MobileOverlay } from '../components';

import Login from './Login';
import Home from './Home';
import NotFound from './NotFound';
import ErrorOccurred from './ErrorOccurred';
import ErrorMultiLogin from './ErrorMultiLogin';

import Validation from './Validation';
import Preferences from './Preferences';
import MicroclimateInput from './MicroclimateDataInput';
import useProj4Defs from '../hooks/useProj4Defs';

import { ThemeProvider as MuiThemeProvider, createTheme as createMuiTheme } from '@mui/material/styles';
import { useTheme } from '../providers/theme';
import { darkThemeOptions as darkMuiThemeOptions, lightThemeOptions as lightMuiThemeOptions } from '../styles/MuiTheme';
import QualityCheck from './QualityCheckTool';
import { USER_ROLES } from '../@types/UserRoles';
import { useAuth } from '../providers/auth-provider';
import { useUser } from '../providers/user-provider';

const Navigation = () => {
  const {
    initialized: authInitialized,
    authenticated,
  } = useAuth();
  const { loaded: proj4Loaded } = useProj4Defs();

  const { isDark } = useTheme();
  const muiTheme = useMemo(() => createMuiTheme(isDark ? darkMuiThemeOptions : lightMuiThemeOptions), [isDark]);

  if (!authInitialized || (!proj4Loaded && authenticated)) {
    return <Loader />
  }

  return (
    <>
      <MuiThemeProvider theme={muiTheme}>
        <MobileOverlay />
        <Router>
          <Switch>
            <Route title='Login' path='/login' component={Login} />
            <Route title='An error occurred' exact path='/error-occurred' component={ErrorOccurred} />
            <Route title='Multi login not allowed' exact path='/error-multi-login' component={ErrorMultiLogin} />
            {authenticated && <AuthenticatedRoutes />}            
            <Route title='Login' path='*' component={Login} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    </>
  );
};

const AuthenticatedRoutes = () => {
  const { hasRole } = useUser();

  return (
    <Switch>
      <Route title='Validation' path='/validation/:MA?' component={Validation} />
      <Route title='Preferences' path='/preferences' component={Preferences} />
      <Route title='Microclimate data input' path='/microclimate-input/:MA?' component={MicroclimateInput} />
      {hasRole(USER_ROLES.QC_OFFICER) && <Route title='Quality Check' path='/quality_check/:MA?' component={QualityCheck} />}
      <Route exact path='/' component={Home} />
      <Route title='Not found' path='*' component={NotFound} />
    </Switch>
  );
};

export default Navigation;
