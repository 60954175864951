import { Skeleton } from '../layout';
import { withRouter, Link } from 'react-router-dom';
import { ReactComponent as Validation } from '../svg/validation.svg';
import { ReactComponent as Completed } from '../svg/completed.svg';
import { ReactComponent as Inspection } from '../svg/inspection.svg';
import { USER_ROLES } from '../@types/UserRoles';
import { useUser } from '../providers/user-provider';

const generateOptions = (hasRole) => [
  {
    label: 'Validation',
    image: <Validation />,
    path: '/validation'
  },
  {
    label: 'Microclimate input',
    image: <Completed />,
    path: '/microclimate-input'
  },
  hasRole(USER_ROLES.QC_OFFICER) && {
    label: 'Quality Check',
    image: <Inspection />,
    path: '/quality_check'
  },
].filter(_ => !!_)

const HomePage = () => {
  const { hasRole } = useUser();

  return (
    <Skeleton header={{ title: null, user: {} }}>
      <div className="home-wrapper">
        <div className="options-wrapper">
          {
            generateOptions(hasRole).map(option => <Option { ...option } key={option.path || option.label} />)
          }
        </div>
      </div>
    </Skeleton>
  )
}

const Option = ({ label, image, path }) => {
  return (
    <Link className="option-wrapper" to={path || '#'} >
      <div className="image">
        <div className="outline" />
        { image }
      </div>
      <h2>{ label }</h2>
    </Link>
  )
}

export default withRouter(HomePage);
